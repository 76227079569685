export const distance = (lat1, lng1, lat2, lng2) => {
  const p = 0.017453292519943295; // Math.PI / 180
  const c = Math.cos;
  const a =
    0.5 -
    c((lat2 - lat1) * p) / 2 +
    (c(lat1 * p) * c(lat2 * p) * (1 - c((lng2 - lng1) * p))) / 2;

  return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
};

export const sortLocations = (locations, near = { lat: 0, lng: 0 }) =>
  locations.sort(
    (
      { geometry: { coordinates: coordinatesA } },
      { geometry: { coordinates: coordinatesB } }
    ) => {
      const [lngA, latA] = coordinatesA;
      const [lngB, latB] = coordinatesB;

      return (
        distance(near.lat, near.lng, latA, lngA) -
        distance(near.lat, near.lng, latB, lngB)
      );
    }
  );
