import PropTypes from 'prop-types';
import classnames from 'classnames';
import Container from '@components/Container';
import Link from '@components/Link';
import ConditionalWrapper from '@components/ConditionalWrapper';
import useLocaleUrl from '@hooks/use-locale-url';
import { Root, BreadCrumb } from './Breadcrumb.module.css';

const Breadcrumb = ({ className, links, includeHome }) => {
  const classes = classnames(Root, className);
  const localeUrl = useLocaleUrl();
  return (
    <Container className={classes}>
      <ul className={BreadCrumb}>
        {links?.map(({ url, text }, index) => {
          const urlObject = new URL(url);
          if (!includeHome && urlObject.pathname === localeUrl('/')) {
            return null;
          }

          return (
            <li key={url + text}>
              <ConditionalWrapper
                condition={index + 1 !== links.length}
                wrapper={(children) => <Link href={url}>{children}</Link>}
              >
                {text}
              </ConditionalWrapper>
            </li>
          );
        })}
      </ul>
    </Container>
  );
};

Breadcrumb.defaultProps = {
  className: '',
  includeHome: false,
};

Breadcrumb.propTypes = {
  className: PropTypes.string,
  includeHome: PropTypes.bool,
};

export default Breadcrumb;
