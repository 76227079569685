/* globals google */
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import AutoSuggestInput from '@features/PageBuilder/components/LocationsMap/components/AutoSuggestInput';
import LoadingIcon from '@components/LoadingIcon';
import MarkerIcon from '@icons/map-marker-alt-solid.svg';

import useSupercluster from 'use-supercluster';
import NoSsr from '@components/NoSsr/NoSsr';
import useUserPosition from '@hooks/use-user-position';
// import { sortLocations } from '@utilities/helpers/geo';
import { captureException } from '@sentry/nextjs';
import Marker from './components/Marker';
import ClusterMarker from './components/ClusterMarker';

import {
  SearchInputWrapper,
  SearchInputInner,
  MarkerIcon as MarkerIconClass,
} from './LocationsMap.module.css';
import FilterButtons from './components/FilterButtons';

const LocationsMap = ({
  className,
  initialCenter,
  initialZoom,
  loadMap,
  isCampaign,
  hasAddressPicker,
  setIsLoading,
  children,
  fetchPosition,
  visibleLocation,
  specificLocations,
  hideFilterButtons,
  points: receivedPoints,
}) => {
  const mapRef = useRef();
  const [googleMapAPILoaded, setGoogleMapAPILoaded] = useState(false);
  const [zoom, setZoom] = useState(initialZoom);
  const [points, setPoints] = useState(receivedPoints);
  const [bounds, setBounds] = useState(null);

  const [center, setCenter] = useState(initialCenter);
  const didFetchPermissionAuto = useRef(false);
  const [hasGeolocationAPI, setHasGeolocationAPI] = useState(false);
  const userDidInteract = useRef();

  const { isFetchingPosition, fetchUserPosition, hasGeolocationGranted } =
    useUserPosition(fetchPosition);

  const showFilterButtons =
    !hideFilterButtons &&
    !specificLocations?.includes('only_selfwash') &&
    !specificLocations?.includes('only_washhalls');

  const [activeFilter, setActiveFilter] = useState('washhalls');

  const onlyNew = specificLocations && specificLocations.includes('only_new');
  const onlySkip =
    (specificLocations && specificLocations.includes('only_selfwash')) ||
    activeFilter === 'skip';

  const onlyWashHalls = activeFilter === 'washhalls';

  const fetchPositionAndZoomIn = (e, forceZoom = false) => {
    if (e) e.preventDefault();

    fetchUserPosition()
      .then((coords) => {
        setIsLoading(false);

        if (!userDidInteract.current || forceZoom) {
          setZoom(11);
          setCenter({
            lat: coords?.latitude,
            lng: coords?.longitude,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        captureException('something went wrong fetching location', error);
      });
  };

  const autoFecthUserLocationIfGranted = () => {
    if (!didFetchPermissionAuto.current && fetchPosition) {
      // eslint-disable-next-line no-param-reassign
      didFetchPermissionAuto.current = true;
      // if permission is already granted, simply fetch it without user clicking anything.

      hasGeolocationGranted().then(() => {
        fetchPositionAndZoomIn();
      });
    }
  };

  useEffect(() => {
    let formattedPoints = receivedPoints || [];

    if (onlyNew || onlySkip) {
      formattedPoints = formattedPoints.filter(
        ({
          properties: { isNew, skip_count: skipCount, halls_count: hallsCount },
        }) =>
          // filter through; if both values are enabled, both conditions have to be met
          // otherwise, only one of them
          onlyNew && onlySkip
            ? skipCount > 0 && isNew
            : (onlyNew && isNew) ||
              (onlySkip && skipCount > 0) ||
              (onlyWashHalls && hallsCount > 0)
      );
    }

    setPoints(formattedPoints);

    if (formattedPoints.length > 0 && !didFetchPermissionAuto.current) {
      autoFecthUserLocationIfGranted();
    }
    // autoFecthUserLocationIfGranted don't change, no need to add it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receivedPoints, didFetchPermissionAuto, onlySkip]);

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: {
      radius: 40,

      // disable the cluster feature, only use functionality to lazy load markers
      maxZoom: 16,
      minZoom: 0,
      minPoints: 30,
    },
  });

  const showLocationOnMap = useCallback(
    (uid, z = false, shouldShow, shouldPan = true, timeout = 300) => {
      const newPoints = [...points];
      const location = newPoints.find((l) => l.properties.uid === uid);

      if (!location) {
        return false;
      }

      if (z) {
        setZoom(z);
      }

      const [lng, lat] = location.geometry.coordinates;
      if (mapRef.current && shouldPan) {
        mapRef.current.panTo({
          lat,
          lng,
        });
      }

      setTimeout(() => {
        setPoints(
          newPoints.map((point) => ({
            ...point,
            properties: {
              ...point.properties,
              show:
                point.properties.uid === uid &&
                ((typeof shouldShow !== 'undefined' && shouldShow) ||
                  !location.properties.show),
            },
          }))
        );
      }, timeout);

      return true;
    },
    [points]
  );

  // when the places-api returns a place, we need to resolve the lat/lng to focus map
  const resolvePlaceId = (location) => {
    const placeId = location?.value?.place_id;
    let geocoder;

    try {
      geocoder = new google.maps.Geocoder();
    } catch (err) {
      // eslint-disable-next-line no-console
      captureException('Failed to init geocoder', err);
      return false;
    }

    // use geocoder to resolve the lat/lng from
    geocoder.geocode({ placeId }, (results, status) => {
      if (status === 'OK' && results[0]) {
        const place = results[0];

        if (place.geometry.viewport) {
          mapRef.current.fitBounds(place.geometry.viewport);
        } else if (place.geometry.bounds) {
          mapRef.current.fitBounds(place.geometry.bounds);
        }

        // show closest location on map, acording to entered address
        // this has been disabled because it creates bug:
        // https://washworld.atlassian.net/browse/ITD-2282
        // if (place.geometry.location) {
        //   const nearestLocation = sortLocations(points, {
        //     lat: place.geometry.location.lat(),
        //     lng: place.geometry.location.lng(),
        //   })?.[0];

        //   if (nearestLocation) {
        //     showLocationOnMap(nearestLocation.properties.uid, false, true);
        //   }
        // }

        // mapRef.current.setZoom(10); // mostly an experiment...
      }
    });
    return true; // happy, linter?
  };

  const onMarkerClickCallback = (key, childProps) => {
    if (childProps.properties && childProps.properties.clickable) {
      // only markers contains properties
      showLocationOnMap(childProps.properties.uid);
    }
  };

  useEffect(() => {
    setHasGeolocationAPI(typeof navigator?.geolocation);
  }, []);

  useEffect(() => {
    showLocationOnMap(visibleLocation, 11);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleLocation]);

  return (
    loadMap && (
      <div className={className}>
        {children}
        <NoSsr>
          {hasAddressPicker && googleMapAPILoaded && (
            <div className={SearchInputWrapper}>
              <div className={SearchInputInner}>
                <AutoSuggestInput onChange={resolvePlaceId} />
                {hasGeolocationAPI && (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a
                    href="#"
                    className={MarkerIconClass}
                    onClick={(e) => fetchPositionAndZoomIn(e, true)}
                  >
                    {isFetchingPosition ? (
                      <LoadingIcon noMaxWait />
                    ) : (
                      <MarkerIcon />
                    )}
                  </a>
                )}
              </div>
            </div>
          )}
        </NoSsr>
        {showFilterButtons ? (
          <FilterButtons onChange={setActiveFilter} />
        ) : null}
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.GOOGLE_MAPS_API_KEY,
            libraries: ['places', 'geometry'],
          }}
          onZoomAnimationStart={() => {
            userDidInteract.current = true;
          }}
          onDrag={() => {
            userDidInteract.current = true;
          }}
          onGoogleApiLoaded={({ map }) => {
            setGoogleMapAPILoaded(true);
            mapRef.current = map;
          }}
          onChange={({ zoom: z, bounds: b, center: newCenter }) => {
            setZoom(z);
            setBounds([b.nw.lng, b.se.lat, b.se.lng, b.nw.lat]);
            setCenter(newCenter);
          }}
          options={() => ({
            fullscreenControl: false,
            fullscreenControlOptions: false,
            // fullscreenControlOptions: {
            //   position: maps.ControlPosition.BOTTOM_LEFT,
            // },
          })}
          onChildClick={onMarkerClickCallback}
          yesIWantToUseGoogleMapApiInternals
          center={center}
          zoom={zoom}
        >
          {clusters &&
            clusters.map((entry) => {
              const [longitude, latitude] = entry.geometry.coordinates;
              const { cluster: isCluster, point_count: pointCount } =
                entry.properties;

              if (isCluster) {
                const markerSize = 3 + pointCount / points.length;
                return (
                  <ClusterMarker
                    isCluster
                    key={`cluster-${entry.id}`}
                    lat={latitude}
                    lng={longitude}
                    points={pointCount}
                    style={{
                      // width: `${2 + (pointCount / points.length) * 20}rem`,
                      // height: `${2 + (pointCount / points.length) * 20}rem`,
                      width: `${markerSize}rem`,
                      height: `${markerSize}rem`,
                      marginLeft: `-${markerSize / 2}rem`,
                      marginTop: `-${markerSize / 2}rem`,
                    }}
                    onClick={() => {
                      const expansionZoom = Math.min(
                        supercluster.getClusterExpansionZoom(entry.id),
                        20
                      );
                      mapRef.current.setZoom(expansionZoom);
                      mapRef.current.panTo({
                        lat: latitude,
                        lng: longitude,
                      });
                    }}
                  />
                );
              }

              return (
                <Marker
                  key={entry.properties.uid}
                  uid={entry.properties.uid}
                  lat={latitude}
                  lng={longitude}
                  mapInstance={mapRef.current}
                  isCampaign={isCampaign}
                  onClose={() =>
                    showLocationOnMap(entry.properties.uid, false, false)
                  }
                  onlySkip={onlySkip}
                  {...entry}
                />
              );
            })}
        </GoogleMapReact>
      </div>
    )
  );
};

LocationsMap.defaultProps = {
  loadMap: true,
  initialZoom: 7,
  hasAddressPicker: true,
  setIsLoading: () => {},
  fetchPosition: true,
  visibleLocation: false,
  hideFilterButtons: false,
  points: [],
  specificLocations: [],
};

LocationsMap.propTypes = {
  loadMap: PropTypes.bool,
  hideFilterButtons: PropTypes.bool,
  initialZoom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hasAddressPicker: PropTypes.bool,
  setIsLoading: PropTypes.func,
  fetchPosition: PropTypes.bool,
  visibleLocation: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  specificLocations: PropTypes.oneOf([
    'only_selfwash',
    'only_washhalls',
    'only_new',
  ]),
  // eslint-disable-next-line react/forbid-prop-types
  points: PropTypes.array,
};

export default LocationsMap;
