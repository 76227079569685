const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds

export const formatPoint = (location) => {
  const softOpening = new Date(location.soft_opening);
  const hasOpened = softOpening < new Date();

  const diffDays = Math.round(Math.abs((new Date() - softOpening) / oneDay));

  const isNew = hasOpened && diffDays < 90;

  return {
    // as required by https://github.com/mapbox/supercluster, Coordinates must be in GeoJSON format (https://tools.ietf.org/html/rfc7946#appendix-A.1)
    type: 'Feature',
    properties: {
      cluster: false,
      show: false,
      clickable: true,
      hasOpened,
      isNew,
      skipCount: location?.skip_count,
      hallsCount: location?.halls_count,
      ...location,
      distance: null,
    },
    geometry: {
      type: 'Point',
      coordinates: Object.values(location.coordinates)
        .reverse() // x,y is the correct order
        .map(Number), // just to be safe, enforce integer values
    },
  };
};
