import Container from '@components/Container';
import React from 'react';
import { isDevelopment } from '../../../shared/utilities/environment';

import * as sections from '../components/Sections/components/index';
import { getBlockComponentName } from './helpers';

const getSectionByData = (block) => {
  // eslint-disable-next-line no-underscore-dangle
  let componentName = getBlockComponentName(block.__typename);

  if (componentName === 'Reusable') {
    const blockType = block.type;
    const reusableSections = {
      do_we_need_one_near_you: 'LocationNearYou',
    };

    componentName = reusableSections[blockType];
  }

  const Component = sections[componentName];

  if (typeof Component === 'undefined') {
    return () =>
      isDevelopment ? (
        <Container>
          Unknown component {block.fieldGroupName} - {componentName}
          <h2>Data</h2>
          <pre>{JSON.stringify(block)}</pre>
        </Container>
      ) : null;
  }

  return Component;
  // // component does exist
  // if (typeof Components[block.fieldGroupName] !== 'undefined') {
  //   return React.createElement(Components[block.fieldGroupName], {
  //     key,
  //     block,
  //   });
  // }
  // // component doesn't exist yet
  // return React.createElement(
  //   () =>
  //     isDevelopment ? (
  //       <div>
  //         The component {block.fieldGroupName} has not been created yet.
  //       </div>
  //     ) : (
  //       ''
  //     ),
  //   { key }
  // );
};

export default getSectionByData;
