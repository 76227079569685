import PropTypes from 'prop-types';
import classnames from 'classnames';
import Link from '@components/Link';
// import WPImage from '@features/WordPress/components/WPImage';
// import Image from 'next/image';
import Accordion from '@components/Accordion';
import { useRouter } from 'next/router';
import useWindowSize from '@hooks/use-window-size';
import { useState } from 'react';
import ChevronIcon from '@icons/chevron.svg';
import resolveLocalPathFromURL from '@utilities/helpers/resolve-local-path-from-url';
import { isMobileBelowWidth } from '@utilities/consts';
import useWordpressTranslation from '@features/WordPress/hooks/use-wordpress-translation';
// import { useTranslation } from 'next-i18next';
import {
  Root,
  MenuItems,
  MenuItemsInner,
  Link as LinkClass,
  ActiveLink,
  // LinkIcon,
  // IconWrapper,
  GoBackAccordion,
  MobileMenu,
  IsOpen,
  Chevron,
  HasMobileMenu,
} from './Sidebar.module.css';

const Sidebar = ({
  className,
  items,
  // noIcons,
  backLink,
  activeLinkClass,
  children,
  hasMobileMenu,
}) => {
  // const { t } = useTranslation(['account', 'common']);
  const translatedString = useWordpressTranslation();
  const { pathname } = useRouter();

  const activeLinkClasses = classnames(ActiveLink, activeLinkClass);
  const currentActiveLink = hasMobileMenu
    ? items.find(({ link }) => resolveLocalPathFromURL(link) === pathname) ||
      items[0]
    : null;
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < isMobileBelowWidth;

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const classes = classnames(
    Root,
    {
      [IsOpen]: menuIsOpen,
      [HasMobileMenu]: hasMobileMenu,
    },
    className
  );

  const didToggleMenu = (e) => {
    e.preventDefault();
    setMenuIsOpen(!menuIsOpen);
  };

  return (
    <div className={classes}>
      {isMobile && hasMobileMenu ? (
        <a
          href={currentActiveLink?.link}
          onClick={didToggleMenu}
          className={MobileMenu}
        >
          {currentActiveLink?.name}
          <ChevronIcon className={Chevron} />
        </a>
      ) : null}
      <div className={MenuItems}>
        <div className={MenuItemsInner}>
          {backLink && (
            <Accordion
              className={GoBackAccordion}
              link={backLink}
              header={translatedString('goBack')}
              flipIcon
            />
          )}
          {items?.length > 0 &&
            items.map(({ name, link, image, ...props }) => (
              <Link
                className={LinkClass}
                activeClassName={activeLinkClasses}
                href={link || '#'}
                key={name + link}
                onClick={() => setMenuIsOpen(false)}
                {...props}
              >
                {/* {!noIcons ? (
                  <div className={IconWrapper}>
                    {image ? (
                      <WPImage
                        layout="fill"
                        className={LinkIcon}
                        sourceUrl={image}
                      />
                    ) : null}
                  </div>
                ) : null} */}
                {name}
              </Link>
            ))}
          {children}
        </div>
      </div>
    </div>
  );
};

Sidebar.defaultProps = {
  className: '',
  noIcons: false,
  backLink: '',
  activeLinkClass: '',
  hasMobileMenu: false,
};

Sidebar.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  noIcons: PropTypes.bool,
  backLink: PropTypes.string,
  activeLinkClass: PropTypes.string,
  hasMobileMenu: PropTypes.bool,
};

export default Sidebar;
