import React from 'react';
import classnames from 'classnames';

import getSectionByData from '@features/PageBuilder/utilities/get-section-by-data';
import { getBlockComponentName } from '@features/PageBuilder/utilities/helpers';
import { useSelector } from 'react-redux';
import {
  Root,
  BackgroundColorWhite,
  BackgroundColorGrey,
  Hero,
} from './Sections.module.css';
import Section from '../../../../shared/components/Section';

const BACKGROUND_COLORS = {
  white: BackgroundColorWhite,
  grey: BackgroundColorGrey,
};

const Sections = ({ components, breadcrumbs, template }) => {
  const { options } = useSelector((state) => state.wordpress);

  const { campaign: globalCampaign } = options || {};

  const PropFormatter = (block) => {
    let blockData = JSON.parse(JSON.stringify(block));
    // eslint-disable-next-line no-underscore-dangle
    const blockName = getBlockComponentName(blockData.__typename);

    switch (blockName) {
      case 'Hero':
        blockData.hasButtons = block.features?.includes('has_buttons') || false;
        break;

      case 'PriceList':
        blockData.prices.models = blockData?.prices?.models?.map((model) => ({
          isPopular: model?.is_popular,
          singleWashPrice: model?.single_wash_price,
          ...model,
        }));
        break;

      case 'Campaign':
        blockData = {
          ...blockData,
          ...(blockData.isCustomCampaign ? blockData.campaign : globalCampaign),
        };
        blockData.isHidden = !blockData.isRunning;
        break;

      default:
        break;
    }

    blockData.blockName = blockName;

    return blockData;
  };

  const renderSection = (block, index) => {
    const noPadding =
      block &&
      block.sectionStyling &&
      block.sectionStyling.includes('noPadding');

    const noPaddingTop =
      block &&
      block.sectionStyling &&
      block.sectionStyling.includes('noPaddingTop');

    const noPaddingBottom =
      block &&
      block.sectionStyling &&
      block.sectionStyling.includes('noPaddingBottom');

    const Component = getSectionByData(block);
    const blockData = PropFormatter(block);

    if (blockData.isHidden) {
      return null;
    }

    const classes = classnames({
      [BACKGROUND_COLORS[block.backgroundColor]]:
        BACKGROUND_COLORS[block.backgroundColor],
      [Hero]: blockData.blockName === 'Hero',
    });

    blockData.sectionsCount = components.length;

    return (
      <Section
        key={index}
        id={block?.idForLinking || undefined}
        className={classes}
        noPadding={
          blockData.blockName === 'LocationsPage' ||
          blockData.blockName === 'Newsletter' ||
          (blockData.blockName === 'Support' &&
            template !== 'DefaultTemplate') ||
          noPadding
        }
        noPaddingTop={blockData.blockName === 'Hero' || noPaddingTop}
        noPaddingBottom={blockData.blockName === 'Hero' || noPaddingBottom}
        marginTop={blockData.blockName === 'FaqSearch'}
      >
        <Component
          breadcrumbs={breadcrumbs}
          template={template}
          className={Root}
          forceInView={index === 0}
          {...blockData}
        />
      </Section>
    );
  };

  return <>{components?.map(renderSection)}</>;
};

export default Sections;
