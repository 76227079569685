/* eslint-disable no-param-reassign */
import { formatISO9075, isValid, parseISO } from 'date-fns';
import useDateTime from '@hooks/use-datetime';
import styles from './FormatDate.module.css';
const { formattedDate } = styles;

interface Props {
  onlyDate?: Boolean;
  date?: string;
  format?: string;
}

const FormatDate = ({
  date: inputtedDate = new Date().toISOString(),
  format = 'PPPPpp',
  onlyDate,
}: Props) => {
  if (onlyDate) {
    format = 'PPPP';
  }

  let isoDate =
    typeof inputtedDate === 'object' ? inputtedDate : parseISO(inputtedDate);
  const { format: formatDate } = useDateTime();

  if (!isValid(isoDate)) {
    isoDate = new Date(inputtedDate);
  }

  try {
    return (
      <span title={formatISO9075(isoDate)} className={formattedDate}>
        {formatDate(isoDate, format)}
      </span>
    );
  } catch (error) {
    return <span>Invalid date</span>;
  }
};

export default FormatDate;
