import PropTypes from 'prop-types';
import classnames from 'classnames';
import Container from '@components/Container';
import Sidebar from '@components/Sidebar';
import Header from '@features/PageBuilder/components/Header';
import Section from '@components/Section';
import FAQSearch from '@features/PageBuilder/components/Sections/components/FAQSearch';
import Questions from '@features/PageBuilder/components/Sections/components/FAQ/components/Questions';
import Accordion from '@components/Accordion';
import Breadcrumb from '@components/Breadcrumb';
import Heading from '@components/Heading';
import { useContext } from 'react';
import i18nStringsContext from '@contexts/i18nStringsContext';
import headerSettingsShape from '@features/WordPress/utilities/prop-types/shapes/header-settings';
import ContactButton from '@components/ContactButton/ContactButton';
import {
  Root,
  AccordionSection,
  Accordion as AccordionClass,
  Breadcrumb as BreadcrumbClass,
  SideBar as SideBarClass,
  SearchSection,
  ContactSection,
  ContactSectionHeader,
} from './SupportPage.module.css';

const SupportPage = ({
  className,
  topLevelTopics,
  topics,
  topic,
  questions,
  breadcrumbs,
  headerSettings,
}) => {
  const classes = classnames(Root, className);

  const {
    needMoreHelp: needMoreHelpText,
    contactUs: contactUsText,
    faqCount: faqCountText,
  } = useContext(i18nStringsContext);

  const contactFormLink = topic?.contact_form_link;

  const faqCount = !topic.parent_id
    ? topics.reduce((sum, { count }) => sum + count, 0)
    : topic?.count;

  const showTopLevelTopics =
    !topics || topic.child_categories || !topic.parent_id;

  const sidebarTopics = showTopLevelTopics ? topLevelTopics : topics;

  return (
    <Container className={classes}>
      <Section>
        <Header
          subheader={faqCountText?.replace('{count}', faqCount)}
          text={topic?.name}
          position="center"
          headerSettings={headerSettings}
        />
        <FAQSearch className={SearchSection} />
      </Section>
      <Breadcrumb
        className={BreadcrumbClass}
        links={breadcrumbs}
        includeHome={false}
      />
      <Section className={AccordionSection} hasSideBar noPaddingTop>
        <Sidebar
          className={SideBarClass}
          items={sidebarTopics}
          noIcons={!!showTopLevelTopics}
          backLink={questions ? topic.parent?.link : null}
        />
        <div>
          {questions && questions.length && !topic.child_categories > 0 ? (
            <Questions whiteBackground key={topic.id} questions={questions} />
          ) : (
            topics.map((t) => (
              <Accordion
                key={t.id}
                className={AccordionClass}
                id={t.id}
                link={t.link || '#'}
                header={`${t.name} (${t.count})`}
              />
            ))
          )}

          {contactFormLink ? (
            <div className={ContactSection}>
              <Heading className={ContactSectionHeader}>
                {needMoreHelpText}
              </Heading>
              <ContactButton contactFormLink={contactFormLink}>
                {contactUsText}
              </ContactButton>
            </div>
          ) : null}
        </div>
      </Section>
    </Container>
  );
};

SupportPage.defaultProps = {
  className: '',
  topic: {},
  topics: [],
  questions: [],
  headerSettings: {},
};

SupportPage.propTypes = {
  className: PropTypes.string,
  topic: PropTypes.instanceOf(Object),
  topics: PropTypes.instanceOf(Array),
  questions: PropTypes.instanceOf(Array),
  headerSettings: headerSettingsShape,
};

export default SupportPage;
