import PropTypes from 'prop-types';
import classnames from 'classnames';
import Breadcrumb from '@components/Breadcrumb';
import { Root } from './Breadcrumb.module.css';

const ACFBreadcrumb = ({ className, breadcrumbs }) => {
  const classes = classnames(Root, className);
  return <Breadcrumb className={classes} links={breadcrumbs} />;
};

ACFBreadcrumb.defaultProps = {
  className: '',
};

ACFBreadcrumb.propTypes = {
  className: PropTypes.string,
};

export default ACFBreadcrumb;
