import Button from '@components/Button';
import Container from '@components/Container';
import Header from '@components/Header';
import Link from '@components/Link';
import Section from '@components/Section';
import useWordpressTranslation from '@features/WordPress/hooks/use-wordpress-translation';
import Image from 'next/image';

import {
  Image as ImageClass,
  ImageContainer,
  Button as ButtonClass,
} from './NotFound.module.css';

const NotFound = () => {
  const translatedString = useWordpressTranslation();

  return (
    <Container>
      <Section>
        <center>
          <Header
            text={translatedString('error404Header')}
            subheader={translatedString('error404Subheader')}
          />

          <div className={ImageContainer}>
            <Image
              width={50}
              height={50}
              className={ImageClass}
              src="/assets/images/404.gif"
              alt="wash world 404 error gif"
              blurDataURL="blur"
              placeholder="blur"
              quality={50}
              priority
            />
          </div>
          <Button
            className={ButtonClass}
            element={Link}
            href="/"
            appearance="primary"
          >
            {translatedString('error404Return')}
          </Button>
        </center>
      </Section>
    </Container>
  );
};

export default NotFound;
