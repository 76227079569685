import PropTypes from 'prop-types';
import classnames from 'classnames';
import Container from '@components/Container';

import Heading from '@components/Heading';
import WPImage from '@features/WordPress/components/WPImage';
import i18nStringsContext from '@contexts/i18nStringsContext';
import { useContext } from 'react';
import Arrow from '@icons/thin-arrow.svg';
import Link from '@components/Link';
import SlantedButtonGroup from '@features/PageBuilder/components/SlantedButtonGroup';
import imageShape from '@features/WordPress/utilities/prop-types/shapes/image';
import linkShape from '@features/WordPress/utilities/prop-types/shapes/link';
import { useSelector } from 'react-redux';
import HTMLText from '../../../HTMLText';

import {
  Container as ContainerClass,
  Root,
  Box,
  Inner,
  Text,
  BoxContentWrapper,
  ButtonGroup as ButtonGroupClass,
  Button as ButtonClass,
  ButtonInner,
  Amount as AmountClass,
  Currency as CurrencyClass,
  ButtonBeforePrice,
  CampaignPriceWrapper,
  ArrowClass,
} from './Campaign.module.css';

const Campaign = ({
  className,
  header,
  text,
  link,
  image,
  beforePrice,
  afterPrice,
}) => {
  const classes = classnames(Root, className);

  const { beforePrice: beforePriceString, campaignPrice: campaignPriceString } =
    useContext(i18nStringsContext);

  const {
    options: { currencySymbol },
  } = useSelector((state) => state.wordpress);

  return (
    <Container className={ContainerClass}>
      <div className={classes}>
        <div className={Box}>
          <div className={Inner}>
            <div className={BoxContentWrapper}>
              <Heading element="h2" size="extra-large" text={header} />
              <HTMLText className={Text}>{text}</HTMLText>
            </div>
            <SlantedButtonGroup
              className={ButtonGroupClass}
              isSplash
              buttons={[
                {
                  className: ButtonClass,
                  innerClass: [ButtonBeforePrice, ButtonInner],
                  disabled: true,
                  appearance: 'white',
                  text: (
                    <>
                      {beforePriceString}
                      <span>
                        <span className={AmountClass}>{beforePrice}</span>
                        <span className={CurrencyClass}>{currencySymbol}</span>
                      </span>
                    </>
                  ),
                },
                {
                  element: Link,
                  href: link?.url,
                  className: ButtonClass,
                  innerClass: ButtonInner,
                  icon: () => <Arrow className={ArrowClass} />,
                  hasArrow: true,
                  text: (
                    <>
                      {campaignPriceString}
                      <span className={CampaignPriceWrapper}>
                        <span className={AmountClass}>{afterPrice || 0}</span>
                        <span className={CurrencyClass}>{currencySymbol}</span>
                      </span>
                    </>
                  ),
                },
              ]}
            />
          </div>
        </div>
        <WPImage layout="fill" objectFill="cover" {...image} />
      </div>
    </Container>
  );
};

Campaign.defaultProps = {
  className: '',
  text: '',
  link: {},
  afterPrice: 0,
  image: {},
};

Campaign.propTypes = {
  className: PropTypes.string,
  header: PropTypes.string.isRequired,
  text: PropTypes.string,
  image: imageShape,
  beforePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  afterPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  link: linkShape,
};

export default Campaign;
