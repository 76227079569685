import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import Accordion from '@components/Accordion';
import HTMLText from '@features/PageBuilder/components/HTMLText';
import { Root, WhiteBackground } from './Questions.module.css';

const Questions = ({ className, questions, whiteBackground }) => {
  const classes = classnames(Root, className);

  const [actualQuestions, setQuestions] = useState(questions); // initialize state
  const didToggle = useCallback((question, shouldBeOpen) => {
    if (question.htmlID) {
      if (window.history.pushState) {
        window.history.pushState(null, null, `#${question.htmlID}`);
      } else {
        window.location.hash = `#${question.htmlID}`;
      }
    }
    setQuestions(
      JSON.parse(JSON.stringify(actualQuestions)).map((q) => ({
        ...q,
        isOpen:
          typeof shouldBeOpen === 'function'
            ? shouldBeOpen(q)
            : shouldBeOpen && q.id === question.id,
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    didToggle(
      {},
      (question) => window.location.hash.replace('#', '') === question.slug
    );
  }, [didToggle]);

  // useEffect(() => {
  //   const id = window.location.hash;
  //   if (id) {

  //   }
  // });

  return (
    <div className={classes}>
      {actualQuestions &&
        actualQuestions.map((q, index) => (
          <Accordion
            didClick={didToggle}
            key={JSON.stringify([q.answer, index])}
            whiteBackground={!whiteBackground}
            className={whiteBackground ? WhiteBackground : null}
            id={q.id}
            header={q.question}
            isOpen={q.isOpen}
            link="#"
            htmlID={q.slug}
          >
            <HTMLText>{q.answer}</HTMLText>
          </Accordion>
        ))}
    </div>
  );
};

Questions.defaultProps = {
  className: '',
  whiteBackground: false,
};

Questions.propTypes = {
  className: PropTypes.string,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    })
  ).isRequired,
  whiteBackground: PropTypes.bool,
};

export default Questions;
