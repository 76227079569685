import PropTypes from 'prop-types';
import classnames from 'classnames';
import Container from '@components/Container';
import MapWithMarkerIcon from '@icons/map-with-marker.svg';
import Header from '@features/PageBuilder/components/Header';
import Button from '@components/Button';

import i18nStringsContext from '@contexts/i18nStringsContext';
import { useContext, useState } from 'react';
import HTMLText from '@features/PageBuilder/components/HTMLText';
import WashUpdateModal from '@features/PageBuilder/components/Sections/components/LocationNearYou/components/WashUpdateModal';
import headerSettingsShape from '@features/WordPress/utilities/prop-types/shapes/header-settings';
import {
  Root,
  BackgroundImage,
  MapIcon,
  Text,
  Button as ButtonClass,
} from './LocationNearYou.module.css';

const LocationNearYou = ({ className, headerSettings }) => {
  const classes = classnames(Root, className);

  const {
    doWeNeedOneNearYou,
    weWantToBeNationWide,
    locationNearYouText,
    getANotification,
  } = useContext(i18nStringsContext);

  const [showModal, isShowingModal] = useState(false);

  const showNewsletterModal = (show) => {
    isShowingModal(show);
  };

  return (
    <Container className={classes}>
      <div className={BackgroundImage} />
      <div className={Text}>
        <MapWithMarkerIcon className={MapIcon} />
        <Header
          subheader={weWantToBeNationWide}
          position="center"
          headerSettings={headerSettings}
        >
          {doWeNeedOneNearYou}
        </Header>
        <HTMLText>{locationNearYouText}</HTMLText>
        <Button
          className={ButtonClass}
          onClick={() => showNewsletterModal(true)}
          appearance="primary"
          size="extra-large"
        >
          {getANotification}
        </Button>
      </div>
      <WashUpdateModal
        show={showModal}
        onClose={() => {
          showNewsletterModal(false);
        }}
      />
    </Container>
  );
};

LocationNearYou.defaultProps = {
  className: '',
  headerSettings: {},
};

LocationNearYou.propTypes = {
  className: PropTypes.string,
  headerSettings: headerSettingsShape,
};

export default LocationNearYou;
