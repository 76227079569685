import { captureException } from '@sentry/nextjs';
import { format as formatDate, parseISO } from 'date-fns';
import { nb, da, de, sv } from 'date-fns/locale';
import { useRouter } from 'next/router';
import { registerLocale } from 'react-datepicker';

const localesToDateFns = {
  da,
  no: nb,
  de,
  se: sv,
};

const useDateTime = () => {
  const { locale } = useRouter();
  const format = (date, formatOrCompare = 'PPPPpp') => {
    try {
      return formatDate(
        typeof date === 'object' ? date : parseISO(date),
        formatOrCompare,
        {
          locale: localesToDateFns[locale],
        }
      );
    } catch (error) {
      captureException('Date error', error, {
        date,
        parsedIso: parseISO(date),
        formatOrCompare,
      });
      return 'Invalid date';
    }
  };

  return {
    format,
    registerLocale: () => registerLocale(locale, localesToDateFns[locale]),
  };
};

export default useDateTime;
