import PropTypes from 'prop-types';
import { useContext } from 'react';
import i18nStringsContext from '@contexts/i18nStringsContext';
import SupportPage from '@features/PageBuilder/components/Sections/components/Support/components/SupportPage';
// import { Root } from './Support.module.css';
import Grid from '../Grid';

const Support = ({
  className,
  backgroundColor,
  template,
  topLevelTopics,
  breadcrumbs,
  topic,
  topics,
  ...props
}) => {
  const { seeFaq: seeFaqText } = useContext(i18nStringsContext);

  const topicsForPage =
    !topic || (!topic.child_categories && !topic.parent_id)
      ? topLevelTopics
      : topics;

  return !topic?.id ? (
    <Grid
      features={['max_three_per_row']}
      layout="boxed"
      backgroundColor={backgroundColor}
      boxes={topLevelTopics.map((t) => ({
        header: t.name,
        text: t.description,
        link: {
          url: t.link,
          title: seeFaqText,
        },
        image: t.image
          ? {
              sourceUrl: t.image,
            }
          : null,
      }))}
    />
  ) : (
    <SupportPage
      topic={topic}
      breadcrumbs={breadcrumbs}
      topics={topicsForPage}
      topLevelTopics={topLevelTopics}
      {...props}
    />
  );
};

Support.defaultProps = {
  className: '',
};

Support.propTypes = {
  className: PropTypes.string,
};

export default Support;
