import PropTypes from 'prop-types';
import headerSettingSizes from '../header-setting-sizes';

const headerSettingTypesShape = PropTypes.shape({
  appearance: headerSettingSizes,
  tag: headerSettingSizes,
});

const headerSettingsShape = PropTypes.shape({
  header: headerSettingTypesShape,
  subheader: headerSettingTypesShape,
});

export default headerSettingsShape;
