import OriginalHeader from '@components/Header';
import { headerPropTypes } from '@components/Header/Header';
import headerSettingsShape from '@features/WordPress/utilities/prop-types/shapes/header-settings';

const ACFHeader = ({ headerSettings, ...props }) => {
  const headerElement = headerSettings?.header?.tag;
  const subheaderElement = headerSettings?.subheader?.tag;

  const headerSize = headerSettings?.header?.size;
  const subheaderSize = headerSettings?.subheader?.size;

  return (
    <OriginalHeader
      headerElement={headerElement !== 'default' ? headerElement : undefined}
      subheaderElement={
        subheaderElement !== 'default' ? subheaderElement : undefined
      }
      headerSize={headerSize}
      subheaderSize={subheaderSize}
      {...props}
    />
  );
};

ACFHeader.defaultProps = {
  headerSettings: {},
};

ACFHeader.propTypes = {
  headerSettings: headerSettingsShape,
  ...headerPropTypes,
};

export default ACFHeader;
