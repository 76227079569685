/* eslint-disable import/prefer-default-export */
/**
 * Get the component name from GraphQL's __typename
 *
 * @param   {string}  typename  E.g. "ContentNode_Sections_Sections_Hero"
 *
 * @return  {string}            e.g. Hero
 */
export const getBlockComponentName = (typename) =>
  typename.split('Sections_Sections_')[1];
