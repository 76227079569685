/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { memo, useState } from 'react';

import dynamic from 'next/dynamic';
import { Root, IsClickable, New, ComingSoon } from './Marker.module.css';
// import MarkerLocation from '../MarkerLocation';

const MarkerLocation = dynamic(() => import('../MarkerLocation'));

const Marker = (props) => {
  const {
    className,
    properties: { clickable, show, hasOpened, isNew },
  } = props;

  const classes = classnames(
    Root,
    {
      [New]: isNew,
      [ComingSoon]: !hasOpened,
      [IsClickable]: clickable,
    },
    className
  );

  const [referenceElement, setReferenceElement] = useState(null);

  return (
    <>
      <div className={classes} ref={setReferenceElement} />
      {show && (
        <MarkerLocation referenceElement={referenceElement} {...props} />
      )}
    </>
  );
};

Marker.defaultProps = {
  className: '',
  isCampaign: false,
};

Marker.propTypes = {
  className: PropTypes.string,
  isCampaign: PropTypes.bool,
};

export default memo(
  Marker,
  (prevProps, nextProps) => prevProps.show !== nextProps.show
);
