import PropTypes from 'prop-types';
import classnames from 'classnames';
import React from 'react';
import { Root } from './ButtonGroup.module.css';

const ButtonGroup = ({ children, className }) => {
  const classses = classnames(Root, className);

  return <div className={classses}>{children}</div>;
};

ButtonGroup.defaultProps = {};

ButtonGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ButtonGroup;
