import React from 'react';
import classnames from 'classnames';
import Container from '@components/Container';
import Header from '@features/PageBuilder/components/Header';
import WPImage from '@features/WordPress/components/WPImage/WPImage';
import Arrow from '@icons/arrow.svg';
import Button from '@features/PageBuilder/components/Button';
import Link from '@components/Link';
import HTMLText from '../../../HTMLText/HTMLText';
import styles from './Grid.module.css';
import { Features, HeaderSettings, SectionButton, SectionLink } from '@shared/types';
const {
  Root,
  GridHeader,
  GridWrapper,
  SingleGridBox,
  GridBox,
  GridBoxContent,
  GridBoxText,
  GridBoxImage,
  GridBoxHeader,
  GridButtonWrapper,
  HasArrows,
  GridOnMobile,
  BoxedLayout,
  GridBoxArrow,
  DefaultLayout,
  MaxThreePerRow,
  WhiteBackground,
  GridBoxLink,
  HideTextOnMobile,
  Button: ButtonClass,
  MaxImagesize,
} = styles;

interface Props {
  className?: string;
  header: string;
  subheader?: string;
  features: Features[];
  layout?: "default" | "boxed";
  boxes: { image: string | object, header?: string, text: string; link?: SectionLink }[];
  button: SectionButton | {};
  headerSettings?: HeaderSettings | {};
  backgroundColor?: string;
  text: React.ReactNode;
}

const Grid = ({
  className = '',
  header = '',
  subheader = '',
  boxes,
  button = {},
  features = [],
  layout = "default",
  backgroundColor,
  text,
  headerSettings = {},
}: Props) => {
  const gridOnMobile = features && features.includes('grid_on_mobile'); // for now we assume gridOnMobile is deactivated when arrows is not used.

  const hideTextOnMobile = features && features.includes('hide_text_on_mobile');

  const hasArrows =
    features && features.includes('has_arrows') && !gridOnMobile;
  const maxThreePerRow = features && features.includes('max_three_per_row');
  const maxImagesize = features && features.includes('max_imagesize');

  const classes = classnames(
    Root,
    {
      [HasArrows]: hasArrows,
      [GridOnMobile]: gridOnMobile,
      [BoxedLayout]: layout === 'boxed',
      [DefaultLayout]: layout === 'default',
      [MaxThreePerRow]: maxThreePerRow,
      [WhiteBackground]: backgroundColor === 'white',
      [HideTextOnMobile]: hideTextOnMobile,
    },
    className
  );

  return (
    <Container>
      <div className={classes}>
        {header && (
          <Header
            className={GridHeader}
            text={header}
            subheader={subheader}
            subtext={text}
            position="center"
            headerSettings={headerSettings}
          />
        )}
        {boxes && boxes.length > 0 && (
          <>
            <div />
            <div
              className={[
                GridWrapper,
                boxes.length === 1 ? SingleGridBox : null,
              ].join(' ')}
            >
              {boxes &&
                boxes.map((box) => {
                  const GridBoxElement =
                    layout === 'boxed' && box.link?.url ? Link : 'div';

                  return (
                    <GridBoxElement
                      className={GridBox}
                      href={
                        layout === 'boxed' && box.link?.url
                          ? box.link.url
                          : null
                      }
                      key={JSON.stringify([box.header, box.image])}
                    >
                      {box.image && (
                        <div
                          className={classnames(GridBoxImage, {
                            [MaxImagesize]: maxImagesize,
                          })}
                        >
                          <WPImage
                            objectFill="contain"
                            layout="fill"
                            {...box.image}
                          />
                        </div>
                      )}
                      <div className={GridBoxContent}>
                        <h3 className={GridBoxHeader}>{box.header}</h3>
                        <HTMLText className={GridBoxText}>{box.text}</HTMLText>
                      </div>
                      {box.link && layout === 'boxed' && (
                        <span className={GridBoxLink}>{box.link.title}</span>
                      )}
                      <div className={GridBoxArrow}>
                        <Arrow />
                      </div>
                    </GridBoxElement>
                  );
                })}
            </div>
          </>
        )}
        {button && (button as SectionButton).link && (
          <div className={GridButtonWrapper}>
            <Button
              className={ButtonClass}
              key={JSON.stringify([(button as SectionButton).link, header])}
              size="extra-large"
              {...button}
            />
          </div>
        )}
      </div>
    </Container>
  );
};

export default Grid;
