import i18nStringsContext from '@contexts/i18nStringsContext';
import SlantedButtonGroup from '@features/PageBuilder/components/SlantedButtonGroup';
import classNames from 'classnames';
import { useContext, useState } from 'react';

import {
  Root,
  FirstButton,
  SecondButton,
  FirstButtonActive as FirstButtonActiveClass,
  SecondButtonActive as SecondButtonActiveClass,
} from './FilterButtons.module.css';

const FilterButtons = ({ onChange }) => {
  const { washalls: washallsText, selfwash: selfwashText } =
    useContext(i18nStringsContext);

  const [activeFilter, setActiveFilter] = useState('washhalls');

  const className = classNames(Root, {
    [FirstButtonActiveClass]: activeFilter === 'washhalls',
    [SecondButtonActiveClass]: activeFilter === 'skip',
  });

  const onFilterChange = (filter) => {
    onChange(filter);
    setActiveFilter(filter);
  };

  return (
    <SlantedButtonGroup
      className={className}
      hasBottomBorder={false}
      buttons={[
        {
          href: '#',
          className: FirstButton,
          text: washallsText,
          appearance: 'white',
          onClick: () => onFilterChange('washhalls'),
        },
        {
          href: '#',
          className: SecondButton,
          text: selfwashText,
          onClick: () => onFilterChange('skip'),
        },
      ]}
    />
  );
};

FilterButtons.defaultProps = {
  // eslint-disable-next-line no-unused-vars
  onChange: (filter) => {},
};

export default FilterButtons;
