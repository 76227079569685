import PropTypes from 'prop-types';
import classnames from 'classnames';
import Container from '@components/Container';
import LoadingIcon from '@components/LoadingIcon';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';
import Button from '@components/Button';
import { useCallback, useContext } from 'react';
import i18nStringsContext from '@contexts/i18nStringsContext';
import NoSsr from '@components/NoSsr/NoSsr';
import { useRouter } from 'next/router';
import SearchIcon from '@icons/search.svg';
import resolveLocalPathFromURL from '@utilities/helpers/resolve-local-path-from-url';
import ContactButton from '@components/ContactButton/ContactButton';
import {
  Root,
  FAQSearchWrapper,
  SearchInput,
  SearchButton,
  SearchIcon as SearchIconClass,
  NeedMoreHelpButton,
} from './FAQSearch.module.css';

const filterQuestions = ({
  data: {
    questions: { nodes },
  },
}) => {
  const questions = {};

  nodes.forEach(({ supportTopics: { nodes: topics }, ...question }) => {
    const category =
      topics.length === 0 ? 'FAQ' : topics.map(({ name }) => name).join(' -> ');
    const option = {
      label: question.title,
      value: question.title,
      url: question.url,
    };

    questions[category] = {
      label: category,
      options: questions[category]?.options
        ? // eslint-disable-next-line no-unsafe-optional-chaining
          [...questions[category]?.options, option]
        : [option],
    };
  });

  return Object.values(questions);
};

const FAQSearchStyles = {
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: () => ({
    display: 'none',
  }),

  control: (provided) => ({
    ...provided,
    height: '3rem',
    minHeight: '3rem',
    boxShadow: 'none',
    borderRadius: 'none',
    backgroundColor: '#f7f7f7', // '#f7f7f7',
    border: 'none',
    borderBottom: `2px solid #e5e5e5`,
  }),

  menu: (provided) => ({
    ...provided,
    width: 'calc(100% + 5rem)',
  }),
};

const FAQSearch = ({ className }) => {
  const classes = classnames(Root, className);
  const { locale } = useRouter();

  const searchQuestions = (searchQuery, callback) => {
    fetch(`${process.env.API_CLIENT_ENDPOINT}/graphql`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
          query Posts {
            questions(where: {search: "${searchQuery}", language: ${locale
          .toString()
          .toUpperCase()}}) {
              nodes {
                title
                content
                slug
                url
                supportTopics(where: {orderby: TERM_GROUP}) {
                  nodes {
                    name
                  }
                }
              }
            }
          }
        `,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        callback(filterQuestions(response));
      });
  };

  const { helpSearchPlaceholder, searchButtonHelp, needMoreHelp, contactUs } =
    useContext(i18nStringsContext);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchHandler = useCallback(debounce(searchQuestions, 250), []);
  const router = useRouter();

  const didSelectQuestion = (question) => {
    router.push(resolveLocalPathFromURL(question.url));
  };

  return (
    <Container className={classes}>
      <div className={FAQSearchWrapper}>
        <NoSsr>
          <AsyncSelect
            cacheOptions
            loadOptions={searchHandler}
            defaultOptions
            className={SearchInput}
            placeholder={helpSearchPlaceholder}
            onChange={didSelectQuestion}
            styles={FAQSearchStyles}
            loadingMessage={() => <LoadingIcon withWrapper noReloadButton />}
            noOptionsMessage={() => (
              <>
                {needMoreHelp}
                <ContactButton
                  size="medium"
                  className={NeedMoreHelpButton}
                  contactFormLink="https://system.etrack1.com/formbuilder/ContactForm.aspx?fid=afcf6b9b-37a7-47d8-8f6e-e6af4c4d9a8a&dc=68714&catondetail=1&pgid=2"
                >
                  {contactUs}
                </ContactButton>
              </>
            )}
          />
        </NoSsr>
        <Button
          className={SearchButton}
          appearance="primary"
          size="large"
          hasArrow={false}
          mobileIcon={() => <SearchIcon className={SearchIconClass} />}
        >
          {searchButtonHelp}
        </Button>
      </div>
    </Container>
  );
};

FAQSearch.defaultProps = {
  className: '',
};

FAQSearch.propTypes = {
  className: PropTypes.string,
};

export default FAQSearch;
