import PropTypes from 'prop-types';
import classnames from 'classnames';
import WPImage from '@features/WordPress/components/WPImage';
import Heading from '@components/Heading';
import { useForm, Controller } from 'react-hook-form';
import Input from '@components/Input';
import Checkbox from '@components/Checkbox';
import Button from '@components/Button';
import HTMLText from '@features/PageBuilder/components/HTMLText';
import Container from '@components/Container';
import { useContext, useState } from 'react';
import i18nStringsContext from '@contexts/i18nStringsContext';
import { useSubscribeNewsletterMutation } from '@shared/services/common';
import { useSelector } from 'react-redux';
import { mapLocaleCodeToZipCodeLength } from '@features/WordPress/utilities/helpers/locale';
import { useRouter } from 'next/router';
import { captureException } from '@sentry/nextjs';
// import { useTranslation } from 'next-i18next';
import useWordpressTranslation from '@features/WordPress/hooks/use-wordpress-translation';
import {
  Root,
  BackgroundImage,
  Content,
  IconWrapper,
  Inner,
  Header as HeaderClass,
  InputWrapper,
  Checkbox as CheckboxClass,
  Button as ButtonClass,
  // DisclaimerText,
  // DisclaimerLink,
} from './Newsletter.module.css';

const Newsletter = ({
  className,
  header,
  backgroundImage,
  icon,
  successText,
  disclaimerText,
  // disclaimerLink,
  type,
}) => {
  // const { t } = useTranslation(['checkout', 'account', 'common']);
  const translatedString = useWordpressTranslation();
  const { locale } = useRouter();
  const classes = classnames(Root, className);
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const { handleSubmit, control } = useForm({
    mode: 'onBlur',
  });
  const {
    options: { consentText: consentFromOptions },
  } = useSelector((state) => state.wordpress);

  const [subscribeNewsletter] = useSubscribeNewsletterMutation();

  const onSubmit = async (data) => {
    subscribeNewsletter(data)
      .unwrap()
      .then(() => setWasSubmitted(true))
      .catch((e) => captureException(e));
  };

  const {
    nameInputPlaceholder,
    zipInputPlaceholder,
    emailInputPlaceholder,
    // acceptWashUpdate: acceptWashUpdateString,
    acceptNewsletter: acceptNewsletterString,
  } = useContext(i18nStringsContext);

  return (
    <div className={classes}>
      <div className={BackgroundImage}>
        <WPImage layout="fill" {...backgroundImage} />
      </div>
      <Container className={Content}>
        <div className={IconWrapper}>
          <WPImage {...icon} />
        </div>
        <div className={Inner}>
          <Heading className={HeaderClass} element="h1" size="extra-large">
            {header}
          </Heading>
          {wasSubmitted ? (
            <>
              <HTMLText>{successText}</HTMLText>
            </>
          ) : (
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={InputWrapper}>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    rules={{
                      minLength: 2,
                    }}
                    render={({ field, fieldState }) => (
                      <Input
                        {...field}
                        type="text"
                        placeholder={nameInputPlaceholder}
                        error={fieldState.error}
                        readableName={translatedString('nameInputLabel')}
                      />
                    )}
                  />
                </div>
                <div className={InputWrapper}>
                  <Controller
                    name="zipcode"
                    control={control}
                    defaultValue=""
                    rules={{
                      minLength: {
                        value:
                          mapLocaleCodeToZipCodeLength(locale).zipcodeLength,
                        message: translatedString('zipInputMinLength').replace(
                          '{inputLength}',
                          mapLocaleCodeToZipCodeLength(locale).zipcodeLength
                        ),
                      },
                      maxLength: {
                        value:
                          mapLocaleCodeToZipCodeLength(locale).zipcodeLength,
                        message: translatedString('zipInputMaxLength').replace(
                          '{inputLength}',
                          mapLocaleCodeToZipCodeLength(locale).zipcodeLength
                        ),
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <Input
                        {...field}
                        type="zipcode"
                        placeholder={zipInputPlaceholder}
                        error={fieldState.error}
                      />
                    )}
                  />
                </div>
                <div className={InputWrapper}>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: true,
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: translatedString('validationInvalidEmail'),
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <Input
                        {...field}
                        type="email"
                        placeholder={emailInputPlaceholder}
                        error={fieldState.error}
                        readableName={translatedString('emailInputLabel')}
                      />
                    )}
                  />
                </div>
                <div className={InputWrapper}>
                  <Controller
                    name="consent"
                    control={control}
                    defaultValue={false}
                    rules={{
                      required: {
                        value: true,
                        message: translatedString('newsLetterRequired'),
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <Checkbox
                        {...field}
                        error={fieldState.error}
                        className={CheckboxClass}
                      >
                        <HTMLText>{consentFromOptions}</HTMLText>
                      </Checkbox>
                    )}
                  />
                </div>
                {type === 'newsletter' ? null : (
                  <Controller
                    name="acceptNewsletter"
                    control={control}
                    defaultValue={false}
                    render={({ field, fieldState }) => (
                      <Checkbox
                        {...field}
                        error={fieldState.error}
                        className={CheckboxClass}
                      >
                        {acceptNewsletterString}
                      </Checkbox>
                    )}
                  />
                )}
                <Button
                  className={ButtonClass}
                  appearance="primary"
                  size="extra-large"
                  type="submit"
                >
                  {translatedString('yesPlease')}
                </Button>
                {disclaimerText && disclaimerText}
              </form>
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

Newsletter.defaultProps = {
  className: '',
};

Newsletter.propTypes = {
  className: PropTypes.string,
};

export default Newsletter;
