import PropTypes from 'prop-types';
import classnames from 'classnames';
import Container from '@components/Container';
import HTMLText from '@features/PageBuilder/components/HTMLText';
import Button from '@features/PageBuilder/components/Button';
import Header from '@features/PageBuilder/components/Header';

import headerSettingsShape from '@features/WordPress/utilities/prop-types/shapes/header-settings';
import Questions from './components/Questions';

import {
  Root,
  ContentWrapper,
  QuestionWrapper,
  Heading as HeadingClass,
  Text as TextClass,
  Button as ButtonClass,
  MobileButton,
} from './FAQ.module.css';

const FAQ = ({
  className,
  header,
  text,
  button,
  questions,
  backgroundColor,
  headerSettings,
}) => {
  const classes = classnames(Root, className);

  return (
    <Container className={classes}>
      {header && text ? (
        <div className={ContentWrapper}>
          <Header
            className={HeadingClass}
            size="extra-large"
            headerSettings={headerSettings}
          >
            {header}
          </Header>
          <HTMLText className={TextClass} element="div">
            {text}
          </HTMLText>
          {button && (
            <Button className={ButtonClass} size="extra-large" {...button} />
          )}
        </div>
      ) : null}
      <div className={QuestionWrapper}>
        <Questions
          whiteBackground={backgroundColor === 'white'}
          questions={questions}
        />
      </div>
      {button && (
        <Button
          className={[ButtonClass, MobileButton].join(' ')}
          size="extra-large"
          {...button}
        />
      )}
    </Container>
  );
};

FAQ.defaultProps = {
  className: '',
  headerSettings: {},
};

FAQ.propTypes = {
  className: PropTypes.string,
  headerSettings: headerSettingsShape,
};

export default FAQ;
