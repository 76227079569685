import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Root } from './ClusterMarker.module.css';

const ClusterMarker = ({ className, points, style, onClick }) => {
  const classes = classnames(Root, className);
  return (
    <div
      className={classes}
      style={style}
      role="button"
      tabIndex="0"
      onClick={onClick}
      onKeyPress={onClick}
    >
      {points}
    </div>
  );
};

ClusterMarker.defaultProps = {
  className: '',
};

ClusterMarker.propTypes = {
  className: PropTypes.string,
  points: PropTypes.number.isRequired,
};

export default ClusterMarker;
