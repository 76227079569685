import PropTypes from 'prop-types';
import classnames from 'classnames';
import HTMLText from '@features/PageBuilder/components/HTMLText';
import CheckmarkCircle from '@icons/checkmark_new.svg';
import Container from '@components/Container';
import {
  Root,
  Bullet,
  BulletIcon,
  BulletIconContainer,
  TextClassContainer,
  Text as TextClass,
} from './TrustBar.module.css';

const TrustBar = ({ className, bullets }) => {
  const classes = classnames(Root, className);
  return (
    <Container className={classes}>
      {bullets &&
        bullets.map(({ text }, index) => (
          <div className={Bullet} key={JSON.stringify([text, index])}>
            <div className={BulletIconContainer}>
              <CheckmarkCircle className={BulletIcon} />
            </div>
            <div className={TextClassContainer}>
              <HTMLText className={TextClass}>{text}</HTMLText>
            </div>
          </div>
        ))}
    </Container>
  );
};

TrustBar.defaultProps = {
  className: '',
};

TrustBar.propTypes = {
  className: PropTypes.string,
  bullets: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default TrustBar;
