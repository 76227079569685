import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import NoSsr from '@components/NoSsr/NoSsr';
import SearchIcon from '@icons/search.svg';
import i18nStringsContext from '@contexts/i18nStringsContext';
import { InputRoot } from '@components/Input/Input.module.css';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import {
  Root,
  SearchIcon as SearchIconClass,
  ValueContainer as ValueContainerClass,
} from './AutoSuggestInput.module.css';

const ValueContainer = ({ children, ...props }) => {
  const { getValue, hasValue, selectProps } = props;
  const { getValue: selectGetValue } = selectProps;
  const value = getValue ? getValue() : selectGetValue();

  return (
    <div className={ValueContainerClass}>
      {hasValue || value ? (
        <SearchIcon className={SearchIconClass} aria-hidden="true" />
      ) : null}

      {children}
    </div>
  );
};

const AutoSuggestInput = ({ className, onChange, ...props }) => {
  const classes = classnames(InputRoot, Root, className);
  const [isActive, setIsActive] = useState(false);

  const { locale } = useRouter();

  const { locationsMapSearchInputPlaceholder: inputPlaceholder } =
    useContext(i18nStringsContext);

  const {
    options: { countryCode = 'DK' },
  } = useSelector((state) => state.wordpress);

  return (
    <NoSsr>
      <GooglePlacesAutocomplete
        debounce={500}
        minLength={2}
        apiOptions={{
          language: locale,
          region: countryCode,
        }}
        autocompletionRequest={{
          componentRestrictions: {
            country: [countryCode],
          },
        }}
        selectProps={{
          className: classes,
          noOptionsMessage: () => inputPlaceholder,
          placeholder: isActive ? null : inputPlaceholder,
          onChange,
          onFocus: () => setIsActive(true),
          onBlur: () => setIsActive(false),
          components: {
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            ValueContainer: (valueContainerProps) => (
              <ValueContainer
                className={ValueContainerClass}
                {...valueContainerProps}
              />
            ),
          },
          styles: {
            control: (provided) => ({
              ...provided,
              borderRadius: 0,
              border: 0,
              boxShadow: 0,
              height: '3rem',
              borderBottom: '1px solid rgba(0,0,0, 0.2)',
              position: 'relative',
            }),
            input: (provided) => ({
              ...provided,
              paddingLeft: '0.5rem',
              width: '100%',
              position: 'absolute',
              top: '7.5px',
              left: '35px',
            }),
            placeholder: (provided) => ({
              ...provided,
              display: isActive ? 'none' : 'inline-block',
              left: '2.6rem',
              marginLeft: '0.5rem',
            }),
            singleValue: (provided) => ({
              ...provided,
              left: '2.6rem',
              maxWidth: 'calc(100% - 5rem)',
            }),
          },
        }}
        {...props}
      />
    </NoSsr>
  );
};

AutoSuggestInput.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Array),
  ]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

AutoSuggestInput.defaultProps = {
  className: '',
  placeholder: '',
  onChange: () => {},
};

export default AutoSuggestInput;
