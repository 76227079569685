import PropTypes from 'prop-types';

const headerSettingSizes = PropTypes.oneOf([
  'default',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
]);

export default headerSettingSizes;
