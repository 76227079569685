import React from 'react';
import PropTypes from 'prop-types';

import c from 'currency.js';
import { useSelector } from 'react-redux';

export const useFormatPrice = ({
  amount = 0,
  noCurrencySymbol = false,
  showDecimals = false,
}) => {
  const {
    options: { currencySymbol },
  } = useSelector((state) => state.wordpress);

  const options = {
    formatWithSymbol: true,
    pattern: noCurrencySymbol ? '#' : '# !',
    negativePattern: noCurrencySymbol ? '-#' : '-# !',
  };

  !showDecimals
    ? (options.precision = amount && amount % 1 !== 0 ? 2 : 0)
    : null;

  options.decimal = ',';
  options.separator = '.';
  options.symbol = currencySymbol;

  return c(amount, options).format();
};

const Price = ({
  classname,
  amount,
  children,
  zeroValue,
  showDecimals,
  noCurrencySymbol,
}) => {
  const priceAmount = amount || children;

  const {
    options: { currencySymbol },
  } = useSelector((state) => state.wordpress);

  const format = () => {
    const options = {
      formatWithSymbol: true,
      pattern: noCurrencySymbol ? '#' : '# !',
      negativePattern: noCurrencySymbol ? '-#' : '-# !',
    };

    !showDecimals
      ? (options.precision = amount && amount % 1 !== 0 ? 2 : 0)
      : null;

    options.decimal = ',';
    options.separator = '.';
    options.symbol = currencySymbol;

    if (!amount && zeroValue) {
      return zeroValue;
    }

    return c(priceAmount, options).format();
  };

  return <p className={classname}>{format()}</p>;
};

Price.propTypes = {
  amount: PropTypes.number.isRequired,
  zeroValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showDecimals: PropTypes.bool,
  noCurrencySymbol: PropTypes.bool,
};

Price.defaultProps = {
  zeroValue: 0,
  showDecimals: false,
  noCurrencySymbol: false,
};

export default Price;
