import { mapLocaleCodeToCountry } from '@features/WordPress/utilities/helpers/locale';
import { useRouter } from 'next/router';
import useSWR from 'swr';

const locationFetcher = (url) => fetch(url).then((response) => response.json());

const useLocationStatus = (uid) => {
  const { locale } = useRouter();
  // Using Date.now() as a cache buster to ensure we always get the latest data.
  // However, this can cause unexpected behavior as the Date.now() changes every millisecond. Therefore, we are dividing by 100000 to get a cache buster that changes every 100 seconds.
  const cacheBuster = Math.floor(Date.now() / 100000);

  const {
    data: location,
    isLoading,
    error,
    isValidating,
  } = useSWR(
    uid
      ? `${
          process.env.API_CLIENT_ENDPOINT
        }/wp-json/ww/v1/locations/${uid}?country=${mapLocaleCodeToCountry(
          locale
        )}&cacheBuster=${cacheBuster}`
      : null,
    locationFetcher,
    {
      revalidate: 60 * 60 * 1000, // Set a revalidation interval (e.g., every hour)
    }
  );

  const hasError =
    !isLoading && !isValidating && !error && location?.message !== '';

  return {
    isLoading,
    hasError,
    requestError: error,
    message: location?.message,
  };
};

export default useLocationStatus;
