/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Arrow from '@icons/arrow.svg';

import Link from '@components/Link';
import {
  Root,
  WhiteBackground,
  Header,
  AccordionContent,
  ToggleWrapper,
  Toggle,
  IsOpen,
  ArrowWrapper,
  Arrow as ArrowClass,
  FlippedIcon,
  HashLink,
} from './Accordion.module.css';

const Accordion = ({
  className,
  id,
  header,
  text,
  children,
  link,
  isOpen,
  didClick,
  flipIcon,
  htmlID,
  whiteBackground,
  hasLinks,
}) => {
  const hasContent = text || children || false;
  const classes = classnames(
    Root,
    {
      [IsOpen]: isOpen,
      [FlippedIcon]: flipIcon,
      [WhiteBackground]: whiteBackground,
    },
    className
  );

  return (
    <div className={classes}>
      <Link
        href={link || '#'}
        onClick={(e) => {
          if (hasContent) {
            e.preventDefault();
          }

          didClick({ id, htmlID }, !isOpen);
        }}
        className={Header}
        {...(hasLinks && {
          href: link || '#',
        })}
      >
        {header}
        {hasContent ? (
          <div className={ToggleWrapper}>
            <div className={Toggle} />
          </div>
        ) : (
          <div className={ArrowWrapper}>
            <Arrow className={ArrowClass} />
          </div>
        )}
      </Link>
      <div className={AccordionContent}>{text || children}</div>
      {htmlID && <span className={HashLink} id={htmlID} />}
    </div>
  );
};

Accordion.defaultProps = {
  id: '',
  className: '',
  isOpen: null,
  text: '',
  didClick: () => {},
  link: '',
  flipIcon: false,
  htmlID: '',
  whiteBackground: true,
};

Accordion.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  header: PropTypes.string.isRequired,
  text: PropTypes.string,
  // slug: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  didClick: PropTypes.func,
  link: PropTypes.string,
  flipIcon: PropTypes.bool,
  whiteBackground: PropTypes.bool,
  htmlID: PropTypes.string,
};

export default Accordion;
