import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ExclamationMarkIcon from '@icons/exclamation-mark.svg';
import CheckmarkIcon from '@icons/checkmark-circle.svg';
import InfoIcon from '@icons/info-circle-solid.svg';
import styles from "./Alert.module.css";
const { Root, IsInfo, Icon } = styles;

interface Props {
  type?: 'info' | 'error' | 'success' | 'warning';
  children: React.ReactNode;
  className: string;
}

const Alert = ({ className, type, children }: Props) => {
  const classes = classNames(
    Root,
    {
      [IsInfo]: type === 'info',
    },
    className
  );
  return (
    <div className={classes}>
      {type === 'error' || type === 'warning' ? (
        <ExclamationMarkIcon className={Icon} />
      ) : null}
      {type === 'info' ? <InfoIcon className={Icon} /> : null}
      {type === 'success' ? <CheckmarkIcon className={Icon} /> : null}
      {children}
    </div>
  );
};

export default Alert;
