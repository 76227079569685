// import styled from 'styled-components';
// import Link from 'next/link';
// import { useRouter } from 'next/router';
import Head from 'next/head';
import NoSections from '@features/PageBuilder/components/NoSections';
import { getLayout } from '@layouts/DefaultLayout';
import fetchAllPages from '@features/WordPress/graphql/handlers/fetch-all-pages';
import fetchPageWithSiteData from '@features/WordPress/graphql/handlers/fetch-page-with-site-data';
import Sections from '@features/PageBuilder/components/Sections';
import Error from '@components/Error';
import Section from '@components/Section';
import NotFound from '@components/NotFound';
import SEO from '../features/WordPress/components/SEO';
import { GetStaticPaths, GetStaticProps } from 'next';
import {
  GeneralOptions,
  Page as IPage,
  SEO as ISEO,
  Sections as ISections,
  Template,
} from '@shared/types';

// commented out, because CloudFlare will cache the static paths
export const getStaticPaths: GetStaticPaths = async ({ defaultLocale }) => {
  const {
    pages: { nodes: pages },
  } = await fetchAllPages();

  const paths = pages.map(({ link, language }) => {
    let slug = link;
    try {
      slug = new URL(link).pathname;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to destruct uri', link);
    }

    const locale = language?.slug || defaultLocale;

    return {
      params: {
        slug: slug.split('/').filter((part) => part !== locale && part),
      },
      locale,
    };
  });

  return {
    paths,
    fallback: 'blocking',
  };
};

// export async function getServerSideProps({
export const getStaticProps: GetStaticProps<any, { slug: string[] }> = async ({
  locale,
  params,
  preview,
}) => {
  let debug = false;
  let path = params?.slug?.join('/').replace('x-debug', '') || '';

  if (params?.slug && params?.slug[params?.slug.length - 1] === 'x-debug') {
    debug = true;
  }

  if (!path) {
    path = `frontpage-${locale}`;
  }

  const { data, errors } = await fetchPageWithSiteData(
    path,
    locale,
    preview,
    debug
  );

  if (!data) {
    return { props: { errors } };
  }

  const { page, generalOptions } = data;

  // for some reason, in production, on sub paths (e.g. /some/subpath)
  // the page object is returned even though it doesn't exist,
  // but the psotType and such is empty - so as a workaround, this will
  const isNotFound = !page || !page.postType;

  return {
    props: {
      errorCode: isNotFound ? 404 : null,
      page,

      generalOptions,
      path,
      locale,
    },
    notFound: isNotFound,
    // Revalidate after 1 second.
    // Cloudflare Workers will cache the response, so it doesn't really matter,
    // ideally it should revalidate on every request, but setting it to 0
    // would result in NO revalidation. We need revalidation, so content team
    // can simply purge the Cloudflare Cache, after they have updated content.
    revalidate: 1,
  };
};

interface Props {
  errorCode: number | null;
  page: IPage;
  generalOptions: GeneralOptions;
  path: string;
  locale: string | undefined;
  seo: ISEO;
  template: Template;
  sections: ISections;
  errors: any;
}

const Page = (props: Props) => {
  const { sections, locale, seo, template, errors, errorCode, page } = props;

  const { id: templateId } = template || {};

  if (errorCode === 404) {
    return (
      <>
        <Head>
          <meta key="robots" name="robots" content="noindex" />
          <meta key="googlebot" name="googlebot" content="noindex" />
          <meta key="status" httpEquiv="Status" content="404 Not Found" />
        </Head>
        <NotFound />
      </>
    );
  }

  if (errors) {
    return <Error errors={errors} />;
  }

  if (!sections) {
    return (
      <Section>
        <div>No sections added to this page</div>
      </Section>
    );
  }

  return (
    <>
      <SEO locale={locale} translations={page?.translations} {...seo} />
      {sections && sections.sections && sections.sections.length > 0 ? (
        <Sections
          breadcrumbs={seo?.breadcrumbs}
          template={templateId}
          components={sections?.sections}
          locale={locale}
        />
      ) : (
        <>
          <NoSections />
        </>
      )}
    </>
  );
};

Page.getLayout = getLayout;

export default Page;
