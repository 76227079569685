/* eslint-disable react/no-array-index-key */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Root,
  Dot,
  CurrentDot,
  IsSkeleton,
} from './SliderPagination.module.css';

const SliderPagination = ({
  className,
  isLoading,
  scrollTo,
  current,
  total,
}) => {
  const classes = classNames(Root, { [IsSkeleton]: isLoading }, className);
  return (
    <div className={classes}>
      {new Array(total).fill('dot').map((n, i) => (
        <button
          key={i}
          className={classNames(Dot, { [CurrentDot]: current === i })}
          onClick={() => scrollTo(i)}
        />
      ))}
    </div>
  );
};

SliderPagination.defaultProps = {
  current: 0,
  total: 0,
  scrollTo: () => {},
  isLoading: false,
};

SliderPagination.propTypes = {
  current: PropTypes.number,
  total: PropTypes.number,
  scrollTo: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default SliderPagination;
