import Head from 'next/head';

const SEO = ({
  title,
  metaDesc,
  metaKeywords,
  canonical,
  metaRobotsNofollow,
  metaRobotsNoindex,
  locale,
  opengraphType,
  opengraphTitle,
  opengraphUrl,
  opengraphModifiedTime,
  schema,
  translations,
}) => (
  <Head>
    <>
      {/* title, meta or any other elements (e.g. script) need to be contained as direct children of the Head element, or wrapped into maximum one level of <React.Fragment> or arrays—otherwise the tags won't be correctly picked up on client-side navigations. */}
      {/* default seo */}
      {title && <title key="title">{title}</title>}
      {metaDesc && (
        <meta name="description" key="description" content={metaDesc} />
      )}
      {metaKeywords && (
        <meta name="keywords" key="keywords" content={metaKeywords} />
      )}
      {canonical && <link rel="canonical" key="canonical" href={canonical} />}
      {canonical && <link rel="canonical" key="canonical" href={canonical} />}
      {(metaRobotsNoindex || metaRobotsNoindex) && (
        <meta
          name="robots"
          key="robots"
          content={`${metaRobotsNoindex || 'index'},${
            metaRobotsNofollow || 'follow'
          }`}
        />
      )}
      {(metaRobotsNoindex || metaRobotsNoindex) && (
        <meta
          name="googlebot"
          key="googlebot"
          content={`${metaRobotsNoindex || 'index'},${
            metaRobotsNofollow || 'follow'
          }`}
        />
      )}

      {/* translations */}
      {translations &&
        translations.map(({ link, language }) => {
          if (!language) {
            return null;
          }

          return (
            <link
              key={`hreflang-${language?.locale}`}
              rel="alternate"
              hrefLang={language?.locale}
              href={link}
            />
          );
        })}

      {/* opengraph */}
      {locale && <meta property="og:locale" key="oglocale" content={locale} />}
      {opengraphType && (
        <meta property="og:type" key="ogtype" content={opengraphType} />
      )}
      {opengraphTitle && (
        <meta property="og:title" key="ogtitle" content={opengraphTitle} />
      )}
      {opengraphType && (
        <meta property="og:type" key="ogtype" content={opengraphType} />
      )}
      {opengraphUrl && (
        <meta property="og:url" key="ogurl" content={opengraphUrl} />
      )}
      {opengraphModifiedTime && (
        <meta
          property="og:article:modified_time"
          key="articlemodifiedtime"
          content={opengraphModifiedTime}
        />
      )}

      {/* twitter? */}
      {/* schema */}
      {schema && schema.raw && (
        <script
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: schema.raw }}
        />
      )}

      {/* noindex on non-production builds */}
      {process.env.ENVIRONMENT !== 'production' ? (
        <>
          <meta key="robots" name="robots" content="noindex,follow" />
          <meta key="googlebot" name="googlebot" content="noindex,follow" />
        </>
      ) : null}
    </>
  </Head>
);

export default SEO;
