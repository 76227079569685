import PropTypes from 'prop-types';
import classnames from 'classnames';
import Image from 'next/image';
import Label from '@components/Label';
import Link from '@components/Link';
import SlantedButtonGroup from '@features/PageBuilder/components/SlantedButtonGroup';
import useLocaleUrl from '@hooks/use-locale-url';
import Tooltip from '@components/Tooltip';
import useWordpressTranslation from '@features/WordPress/hooks/use-wordpress-translation';
import MarkerIcon from '@icons/map-marker-alt-solid.svg';

import Button from '@components/Button';
import useUserPosition from '@hooks/use-user-position';
import {
  Root,
  GetPositionButton,
  ContentWrapper,
  LeftSide,
  RightSide,
  RightSideInner,
  LocationName,
  LocationAddress,
  LocationGoogleLink,
  WithImage,
  LocationImage,
  Label as LabelClass,
  ComingSoonLabel as ComingSoonLabelClass,
  ButtonGroup,
  Button as ButtonClass,
  IsSkeleton,
  GrayBackground,
  MarkerIcon as MarkerIconClass,
} from './LocationCard.module.css';

const Locations = ({
  className,
  address,
  distance,
  image,
  showImage,
  showButtons,
  isNew,
  hasOpened,
  url,
  isSkeleton,
  clicked,
  uid,
  name,
  backgroundColor,
  coordinates,
  ...props
}) => {
  const classes = classnames(
    Root,
    {
      [WithImage]: showImage,
      [IsSkeleton]: isSkeleton,
      [GrayBackground]: backgroundColor === 'gray',
    },
    className
  );

  const translatedString = useWordpressTranslation();

  const localeUrl = useLocaleUrl();

  const formattedName = name && name.includes('-') ? name.split('-')[0] : name;

  const { hasPosition, fetchUserPosition, isFetchingPosition } =
    useUserPosition();

  return (
    <div className={classes} {...props}>
      <div
        role="button"
        tabIndex="0"
        onClick={(e) => clicked(uid, e)}
        onKeyPress={(e) => clicked(uid, e)}
      >
        {showImage && (
          <div className={LocationImage}>
            {isNew && (
              <Label appearance="splash" className={LabelClass}>
                {translatedString('newLabel')}
              </Label>
            )}
            {!hasOpened && (
              <Label
                appearance="dark"
                className={[LabelClass, ComingSoonLabelClass].join(' ')}
              >
                {translatedString('comingSoonLabel')}
              </Label>
            )}
            <Image
              alt="location"
              src={image || '/assets/images/Demo/WashWorld_lokation-min.jpg'}
              layout="fill"
              blurDataURL="blur"
              placeholder="blur"
              quality={50}
              priority
            />
          </div>
        )}
        <div className={ContentWrapper}>
          <div className={LeftSide}>
            <div className={LocationName}>{formattedName}</div>
            <div
              // href={`https://maps.google.com/?ll=${coordinates?.y},${
              //   coordinates?.x
              // }&q=${encodeURIComponent(address)}`}
              className={LocationAddress}
            >
              {address}
            </div>
            <Link
              key={LocationAddress}
              className={LocationGoogleLink}
              href={`https://www.google.com/maps/place/${coordinates?.y},${coordinates?.x}`}
              target="_blank"
            >
              <MarkerIcon className={MarkerIconClass} />
              {translatedString('showOnMap')}
            </Link>
          </div>
          {distance || isSkeleton ? (
            <div className={RightSide}>
              <span className={RightSideInner}>
                {!isSkeleton ? `${parseFloat(distance.toFixed(1))} km` : null}
                {!hasPosition && !isSkeleton ? (
                  <Tooltip clickable>
                    {translatedString('getLocationText')}
                    <Button
                      className={GetPositionButton}
                      fullWidth
                      appearance="dark"
                      size="small"
                      hasArrow={false}
                      onClick={fetchUserPosition}
                      disabled={isFetchingPosition}
                    >
                      {isFetchingPosition
                        ? translatedString('loading')
                        : translatedString('getLocationButton')}
                    </Button>
                  </Tooltip>
                ) : null}
              </span>
            </div>
          ) : null}
        </div>
      </div>
      {showButtons && (
        <SlantedButtonGroup
          className={ButtonGroup}
          buttonClass={ButtonClass}
          buttons={[
            {
              element: Link,
              href: url,
              disabled: isSkeleton,
              text: translatedString('readMore'),
              id: `map-read-more-${uid}`,
            },
            {
              element: Link,
              href: localeUrl(`/checkout?location=${uid}`),
              disabled: isSkeleton,
              text: translatedString('becomeMember'),
              id: `map-become-member-${uid}`,
            },
          ]}
        />
      )}
    </div>
  );
};

Locations.defaultProps = {
  className: '',
  name: '',
  address: '',
  distance: 0,
  showImage: false,
  showButtons: false,
  isNew: false,
  hasOpened: true,
  isSkeleton: false,
  clicked: () => {},
};

Locations.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  address: PropTypes.string,
  distance: PropTypes.number,
  showImage: PropTypes.bool,
  showButtons: PropTypes.bool,
  isNew: PropTypes.bool,
  hasOpened: PropTypes.bool,
  isSkeleton: PropTypes.bool,
  clicked: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

export default Locations;
