const { useRouter } = require('next/router');

const useLocaleUrl = () => {
  const { locale, defaultLocale } = useRouter();

  return (url) =>
    locale !== defaultLocale ? `${locale}/${url}`.replace('//', '/') : url;
};

export default useLocaleUrl;
