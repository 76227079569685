import PropTypes from 'prop-types';
import classnames from 'classnames';
import Heading from '@components/Heading';
import HTMLText from '@features/PageBuilder/components/HTMLText';
import headerSettingSizes from '@features/WordPress/utilities/prop-types/header-setting-sizes';
import {
  Root,
  Splash,
  SubHeader,
  PositionLeft,
  PositionRight,
  PositionCenter,
  SubText,
} from './Header.module.css';

const POSITIONS = {
  left: PositionLeft,
  right: PositionRight,
  center: PositionCenter,
};

const Header = ({
  element: Element,
  headerElement,
  headerSize,
  subheaderElement,
  subheaderSize,
  subheader,
  text,
  subtext,
  size,
  className,
  position,
  children,
  splash,
  ...props
}) => {
  const classses = classnames(
    Root,
    {
      [POSITIONS[position]]: POSITIONS[position],
      [Splash]: splash,
    },
    className
  );
  return (
    <Element className={classses} {...props}>
      {subheader ? (
        <Heading
          element={subheaderElement}
          className={SubHeader}
          size={subheaderSize ?? 'auto'}
          upperCase
          hasBrandColor
        >
          {subheader}
        </Heading>
      ) : null}
      <Heading
        size={headerSize}
        element={headerElement}
        text={text || children}
      />
      {subtext && <HTMLText className={SubText}>{subtext}</HTMLText>}
    </Element>
  );
};

Header.defaultProps = {
  className: '',
  subheaderElement: 'h2',
  subheaderSize: undefined,
  headerElement: 'h1',
  headerSize: undefined,
  subheader: '',
  position: 'left',
  splash: false,
  element: 'div',
  text: '',
  subtext: '',
};

const element = PropTypes.oneOfType([PropTypes.string, PropTypes.element]);

export const headerPropTypes = {
  className: PropTypes.string,
  subheader: PropTypes.string,
  subheaderElement: element,
  subheaderSize: headerSettingSizes,
  headerElement: element,
  headerSize: headerSettingSizes,
  text: PropTypes.string,
  subtext: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.objectOf(Array),
  ]),
  position: PropTypes.oneOf(['left', 'right', 'center']),
  splash: PropTypes.bool,
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.func,
  ]),
};

Header.propTypes = headerPropTypes;

export default Header;
