import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Root, IsSplash, IsDark, IsTransperant } from './Label.module.css';

const APPEARANCES = {
  dark: IsDark,
  splash: IsSplash,
  transperant: IsTransperant,
};

const Label = ({ className, appearance, children }) => {
  const classes = classnames(
    Root,
    {
      [APPEARANCES[appearance]]: APPEARANCES[appearance],
    },
    className
  );
  return <span className={classes}>{children}</span>;
};

Label.defaultProps = {
  className: '',
  appearance: 'transperant',
};

Label.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  appearance: PropTypes.oneOf(['splash', 'dark', 'transperant']),
};

export default Label;
