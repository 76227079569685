import classnames from 'classnames';
import PropTypes from 'prop-types';
import Container from '@components/Container';
import Header from '@features/PageBuilder/components/Header';
import ButtonGroup from '@components/ButtonGroup/ButtonGroup';
import Button from '@features/PageBuilder/components/Button';
import WPImage from '@features/WordPress/components/WPImage';
import buttonShape from '@features/WordPress/utilities/prop-types/shapes/button';
import headerSettingsShape from '@features/WordPress/utilities/prop-types/shapes/header-settings';

import {
  Root,
  ImageWrapper,
  ButtonGroup as ClassButtonGroup,
  Button as ButtonClass,
  SubText,
  // TextPositionLeft,
  TextPositionRight,
  TextPositionCenter,
  IsHalfWidth,
  HeaderCenter,
} from './Text.module.css';
import HTMLText from '../../../HTMLText/HTMLText';

const POSITIONS = {
  // left: TextPositionLeft,
  right: TextPositionRight,
  center: TextPositionCenter,
};

const Text = ({
  className,
  header,
  subheader,
  headerPosition,
  buttons,
  content,
  subtext,
  textPosition,
  features,
  image,
  headerSettings,
}) => {
  const isHalfWidth = features && features.includes('half_width');
  const hasSubtext = features && features.includes('has_subtext');

  const classes = classnames(
    Root,
    {
      [POSITIONS[textPosition]]: POSITIONS[textPosition],
      [IsHalfWidth]: isHalfWidth,
      [HeaderCenter]: headerPosition === 'center',
    },
    className
  );

  return (
    <Container>
      <div className={classes}>
        {image ? (
          <div className={ImageWrapper}>
            <WPImage {...image} />
          </div>
        ) : null}
        {header || subheader ? (
          <Header
            text={header}
            subheader={subheader}
            position={headerPosition}
            headerSettings={headerSettings}
          />
        ) : null}
        <HTMLText>{content}</HTMLText>
        {buttons ? (
          <ButtonGroup className={ClassButtonGroup}>
            {buttons.map((button, index) => (
              <Button
                size="extra-large"
                key={JSON.stringify([
                  header,
                  subheader,
                  index,
                  button?.link?.url,
                  button?.link?.title,
                ])}
                className={ButtonClass}
                {...button}
              />
            ))}
          </ButtonGroup>
        ) : null}
        {hasSubtext && subtext ? (
          <HTMLText className={SubText}>{subtext}</HTMLText>
        ) : null}
      </div>
    </Container>
  );
};

Text.defaultProps = {
  className: '',
  textPosition: 'left',
  header: '',
  subheader: '',
  headerPosition: null,
  buttons: [],
  content: '',
  subtext: '',
  headerSettings: {},
};

Text.propTypes = {
  header: PropTypes.string,
  subheader: PropTypes.string,
  headerPosition: PropTypes.string,
  buttons: PropTypes.arrayOf(buttonShape),
  content: PropTypes.string,
  subtext: PropTypes.string,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Array),
  ]),
  textPosition: PropTypes.oneOf(['left', 'center', 'right']),
  headerSettings: headerSettingsShape,
};

export default Text;
