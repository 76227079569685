import Button from '@components/Button';
import Modal from '@components/Modal';

import { useState } from 'react';
import { ContactIframe, ContactModalContent } from './ContactButton.module.css';

const ContactButton = ({ children, contactFormLink, ...props }) => {
  const [isShowingModal, setIsShowingModal] = useState(false);
  return (
    <>
      <Button
        onClick={() => setIsShowingModal(true)}
        size="extra-large"
        appearance="dark"
        hasArrow={false}
        {...props}
      >
        {children}
      </Button>

      <Modal
        show={isShowingModal}
        contentClass={ContactModalContent}
        onClose={() => setIsShowingModal(false)}
      >
        <iframe
          className={ContactIframe}
          title={children}
          src={contactFormLink}
        />
      </Modal>
    </>
  );
};

export default ContactButton;
