import { useGetIPLocationQuery } from '@features/WordPress/services/wordpress';
import {
  setBrowserCoordinates,
  setIsFetchingBrowserPosition,
} from '@shared/store/app';
import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useUserPosition = (autoFetch = true) => {
  const { browserCoordinates, isFetchingBrowserPosition } = useSelector(
    (state) => state.app
  );

  const dispatch = useDispatch();

  const { data: ipInfo = {}, isLoading: isFetchingIpPosition } =
    useGetIPLocationQuery();

  const didFetchPermissionAuto = useRef(false);

  /**
   * Function to fetch position
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchUserPosition = () =>
    new Promise((resolve, reject) => {
      if (!navigator || !navigator.geolocation) {
        // new Error() have been disabled specfically for below line
        // because it would otherwise bloat up our Sentry logging system.
        // eslint-disable-next-line prefer-promise-reject-errors
        return reject('Webbrowser does not support geolocation');
      }

      dispatch(setIsFetchingBrowserPosition(true));

      navigator?.geolocation.getCurrentPosition(
        ({ coords }) => {
          resolve(coords);
          dispatch(
            setBrowserCoordinates({
              lat: coords.latitude,
              lng: coords.longitude,
            })
          );
        },
        (error) => {
          dispatch(setIsFetchingBrowserPosition(false));
          // parentSetIsLoading(false);
          // eslint-disable-next-line no-console
          console.error('Something went wrong fetching location', error);
          // reject(error);
          reject();
        }
      );
    });

  /**
   * Function to check if browser has permission
   */

  const hasGeolocationGranted = () =>
    new Promise((resolve, reject) => {
      if (typeof navigator?.permissions?.query !== 'function') {
        return null; // reject(new Error('Browser does not have permissions query'))
      }
      return navigator.permissions
        .query({ name: 'geolocation' })
        .then(({ state }) => {
          if (state === 'granted') {
            resolve(true);
          }
        })
        .catch(reject);
    });

  /**
   * Autofetch
   *
   * @return  {[type]}  [return description]
   */

  useEffect(() => {
    if (!didFetchPermissionAuto.current && autoFetch) {
      didFetchPermissionAuto.current = true;
      // if permission is already granted, simply fetch it without user clicking anything.
      hasGeolocationGranted()
        .then(() => {
          fetchUserPosition();
        })
        .catch();
    }
  }, [autoFetch, didFetchPermissionAuto, fetchUserPosition]);

  /**
   * Check if we have user position
   */

  const hasPosition = useMemo(
    () => !!browserCoordinates?.lat,
    [browserCoordinates]
  );

  /**
   * The user position, either the coordinates from browser (if we got them)
   * or the IP info coordinates
   */

  const userPosition = hasPosition
    ? {
        lat: browserCoordinates.lat,
        lng: browserCoordinates.lng,
      }
    : {
        lat: ipInfo?.lat,
        lng: ipInfo?.lng,
      };

  return {
    hasPosition,
    fetchUserPosition,
    isFetchingPosition: isFetchingIpPosition || isFetchingBrowserPosition,
    hasGeolocationGranted,
    hasGeolocationAPI:
      typeof navigator !== 'undefined' && typeof navigator?.geolocation,
    userPosition,
  };
};

export default useUserPosition;
