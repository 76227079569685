import React, { useContext } from 'react';
import classnames from 'classnames';
import LoadingIcon from '@components/LoadingIcon';
import i18nStringsContext from '@contexts/i18nStringsContext';
import useLocationStatus from '@hooks/use-location-status';
import styles from './LocationStatusDot.module.css';
const {
  Dot,
  RedDot,
  YellowDot,
  StatusText,
  IsLoading,
  LoadingIcon: LoadingIconClass,
} = styles

interface Props {
  className: string;
  initialMessage?: string;
  successMessage: string | boolean;
  uid: string
}

const LocationStatusDot = ({
  className,
  initialMessage = '',
  successMessage = "OK",
  uid = '',
}: Props) => {
  const { hasError, message, isLoading, requestError } = useLocationStatus(uid);

  const statusDotClasses = classnames(Dot, {
    [RedDot]: hasError,
    [IsLoading]: isLoading,
    [YellowDot]: requestError || isLoading,
  });

  const {
    loading: loadingText,
    // noReportedIssues: noReportedIssuesString,
    // status: statusString,
  } = useContext(i18nStringsContext);

  return !successMessage && !hasError ? null : (
    <div className={className}>
      <div className={statusDotClasses} />
      <span className={StatusText}>
        {isLoading ? (
          <>
            <LoadingIcon noMaxWait className={LoadingIconClass} /> {loadingText}
          </>
        ) : (
          message || initialMessage || successMessage
        )}
      </span>
    </div>
  );
};

export default LocationStatusDot;
