// import PropTypes from 'prop-types';
// import classnames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import i18nStringsContext from '@contexts/i18nStringsContext';
import ClockIcon from '@icons/clock-regular.svg';
import MapMarkerIcon from '@icons/map-marker-alt-solid.svg';
import HTMLText from '@features/PageBuilder/components/HTMLText';
import LocationStatusDot from '@features/PageBuilder/components/Sections/components/LocationsPage/components/LocationStatusDot';
import Section from '@components/Section';
import Locations from '@features/PageBuilder/components/Sections/components/Locations';
import useLocaleUrl from '@hooks/use-locale-url';
import { formatPoint } from '@features/PageBuilder/utilities/format-point';

import Alert from '@components/Alert';
import classNames from 'classnames';
import FormatDate from '@components/FormatDate';
import { useRouter } from 'next/router';
import styles from './LocationsPage.module.css';
const {
  HeroText,
  IconsWithText,
  Icon,
  IconWrapper,
  StatusDot,
  StatusDotInGrid,
  GridTextSeperator,
  Alert: AlertClass,
} = styles;
import Hero from '../Hero';
import Grid from '../Grid';
import LocationNearYou from '../LocationNearYou';
import { SectionsSection } from '@shared/types';
import {
  ServiceUnitUids,
  ServiceUnits,
  SingleLocation,
} from '@shared/types/common';

import LoadingIcon from '@components/LoadingIcon';
import { useAppSelector } from '@shared/store';

const LocationsPage = ({
  name,
  openHours,
  location,
  heroText,
  shortName,
  uid,
  maxHeight,
  mirrorLength,
  wheelWidth,
  grandOpening,
}: SectionsSection) => {
  const {
    becomeMember: becomeMemberString,
    findDirections: findDirectionsString,
    height: heightString,
    mirrorLength: mirrorLengthString,
    maxWheelWidth: maxWheelWidthString,
    environmentalFriendlyWash: environmentalFriendlyWashString,
    easyAndEffectiveCarWash: easyAndEffectiveCarWashString,
    weHaveMoreCloseBy: weHaveMoreCloseByString,
    becomeMemberFrom: becomeMemberFromString,
    alsoSee: alsoSeeString,
    whatYouFindOnLocation: whatYouFindOnLocationString,
    multipleWashhalls: multipleWashhallsString,
    singleWashhall: singleWashhallString,
    multipleSkip: multipleSkipString,
    singleSkip: singleSkipString,
    alwaysOpen: alwaysOpenString,
    limitedOpenHours: limitedOpenHoursString,
    hallsSubtext,
    skipSubtext,
    alwaysOpenSubtext,
    limitedOpenHoursSubtext,
    alwaysOpenText,
    comingSoonLabel,
    vacuum,
    vacuumPlural,
    highPressurePreWash,
    highPressurePreWashPlural,
    matCleaner,
    matCleanerPlural,
  } = useContext(i18nStringsContext);
  const { locale } = useRouter();
  const localeUrl = useLocaleUrl();

  const { locations } = useAppSelector((state) => state.locations);

  const [allLocations, setAllLocations] = useState<SingleLocation[]>([]);

  useEffect(() => {
    if (locations && locations?.length > 0) {
      setAllLocations(locations);
    }
  }, [locations]);

  const serviceUnits: ServiceUnits | undefined = allLocations?.find(
    (location) => location.uid === uid
  )?.service_units;

  const text = (
    <>
      <div className={IconsWithText}>
        <span className={IconWrapper}>
          <ClockIcon className={Icon} />
          <span
            className="content"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: openHours === '24/7' ? alwaysOpenText || '' : openHours,
            }}
          />
        </span>
        <span className={IconWrapper}>
          <MapMarkerIcon className={Icon} />
          {location?.streetAddress}
        </span>
      </div>
      {grandOpening && new Date(grandOpening) >= new Date() && (
        <Alert className={AlertClass}>
          {comingSoonLabel} -&nbsp;
          <FormatDate date={grandOpening} onlyDate />
        </Alert>
      )}

      {heroText ? <HTMLText className={HeroText}>{heroText}</HTMLText> : null}
    </>
  );

  /**
   * Toggle between map and image
   */

  // const image = { sourceUrl: locationImage } || {
  //   sourceUrl: '/assets/images/Demo/WashWorld_lokation-min.jpg',
  // };

  /**
   * GridText
   */

  const gridText = [];

  if (maxHeight) {
    gridText.push(`${heightString}: ${maxHeight}m`);
  }
  if (mirrorLength) {
    gridText.push(`${mirrorLengthString}: ${mirrorLength}m`);
  }
  if (wheelWidth) {
    gridText.push(`${maxWheelWidthString}: ${wheelWidth}m`);
  }

  const boxes = [];

  console.log('serviceUnits', serviceUnits);

  // open hours
  if (openHours === '24/7') {
    boxes.push({
      image: { sourceUrl: '/assets/icons/always-open.svg' },
      header: alwaysOpenString,
      text: alwaysOpenSubtext || '',
    });
  } else {
    boxes.push({
      image: { sourceUrl: '/assets/icons/limited-open.svg' },
      header: limitedOpenHoursString?.replace('{openHours}', openHours),
      text: limitedOpenHoursSubtext || '',
    });
  }

  const countServiceUnitsUp = (
    count: number,
    sourceURL: string,
    multipleString: string | undefined,
    singleString: string | undefined,
    subText?: string
  ) => {
    if (count > 0) {
      boxes.push({
        image: { sourceUrl: sourceURL },
        header:
          count > 1
            ? multipleString?.replace('{count}', count.toString())
            : singleString?.replace('{count}', count.toString()),
        text: subText || '',
      });
    }
  };

  // Washing halls
  countServiceUnitsUp(
    serviceUnits ? serviceUnits.hall?.total_count : 0,
    '/assets/icons/washhall.svg',
    multipleWashhallsString,
    singleWashhallString,
    hallsSubtext
  );

  // Self wash
  countServiceUnitsUp(
    serviceUnits ? serviceUnits.self_wash?.total_count : 0,
    locale === 'de' ? '/assets/icons/skip-de.svg' : '/assets/icons/skip.svg',
    multipleSkipString,
    singleSkipString,
    skipSubtext
  );

  // Vacuums
  const totalVacuumMachine = serviceUnits?.vacuum?.total_count || 0;
  const hasSelfWashMachine =
    serviceUnits?.self_wash?.details &&
    serviceUnits?.self_wash?.details?.length > 0;

  if (totalVacuumMachine > 0 || hasSelfWashMachine) {
    // vacuums have more hoses
    const vacuumCount = serviceUnits?.vacuum?.details
      ? serviceUnits.vacuum.details.length
      : 0;

    const selfWashVacuums =
      serviceUnits?.self_wash?.details?.reduce((prev, curr) => {
        if (curr.uid === ServiceUnitUids.CHRIST_COMPACT_VACUUM) {
          return prev + 1;
        }
        return prev;
      }, 0) || 0;

    const totalVacuumCount = vacuumCount + selfWashVacuums;

    countServiceUnitsUp(
      totalVacuumCount,
      '/assets/icons/vacuum-cleaner.svg',
      vacuumPlural,
      vacuum
    );
  }

  // High pressure pre wash
  countServiceUnitsUp(
    serviceUnits?.pre_wash?.total_count || 0,
    '/assets/icons/high-pressure.svg',
    highPressurePreWashPlural,
    highPressurePreWash
  );

  // Pre wash
  /*countServiceUnitsUp(
    serviceUnits?.pre_wash?.total_count || 0,
    '/assets/icons/soapfaucit.svg',
    preWashPlural,
    preWash
  );*/

  // Mat cleaners
  countServiceUnitsUp(
    serviceUnits?.mat_cleaner?.total_count || 0,
    '/assets/icons/mat-cleaner.svg',
    matCleanerPlural,
    matCleaner
  );

  return (
    <>
      <Hero
        subheader={easyAndEffectiveCarWashString}
        header={name}
        text={text}
        headerSettings={{
          header: {
            appearance: 'h3',
          },
        }}
        map={{
          initialCenter: { lat: location?.latitude, lng: location?.longitude },
          initialZoom: +location?.zoom,
          hasAddressPicker: false,
          fetchPosition: false,
          requestKey: uid,
          hideFilterButtons: true,
          points: [
            formatPoint({
              clickable: false,
              soft_opening: grandOpening,
              coordinates: {
                y: location?.latitude,
                x: location?.longitude,
              },
            }),
          ],
        }}
        imagePosition="right"
        buttons={[
          {
            appearance: 'primary',
            link: {
              url: localeUrl(`/checkout?location=${uid}`),
              title: becomeMemberString,
            },
          },
          {
            appearance: 'secondary',
            link: {
              url: `https://maps.google.com/?ll=${location?.latitude},${
                location?.longitude
              }&q=${encodeURIComponent(location?.streetAddress)}`,
              title: findDirectionsString,
              target: '_blank',
            },
          },
        ]}
        showMap
        hasButtons
      />
      {allLocations?.length > 0 ? (
        <Section>
          <Grid
            subheader={environmentalFriendlyWashString}
            header={`${whatYouFindOnLocationString} ${shortName}`}
            text={
              <>
                {gridText
                  .map((t) => <span key={t}>{t}</span>)
                  .reduce<React.ReactNode>(
                    (prev, curr) => [
                      prev,
                      <span key={curr.key} className={GridTextSeperator}>
                        •
                      </span>,
                      curr,
                    ],
                    []
                  )}
                <LocationStatusDot
                  className={classNames(StatusDot, StatusDotInGrid)}
                  uid={uid}
                  successMessage={false}
                />
              </>
            }
            boxes={boxes}
            button={{
              appearance: 'primary',
              link: {
                url: localeUrl(`/checkout?location=${uid}`),
                title: becomeMemberFromString,
              },
            }}
            features={['hide_text_on_mobile']}
          />
        </Section>
      ) : (
        <LoadingIcon size="large" withWrapper noReloadButton noMaxWait />
      )}
      <Section>
        <Locations
          subheader={weHaveMoreCloseByString}
          header={alsoSeeString || ''}
          features={['show_specific_locations']}
          specificLocationsType={['near_point']}
          nearCoordinates={{
            lat: location?.latitude,
            lng: location?.longitude,
          }}
          excludeIds={[uid]}
          locationsShowImages
        />
      </Section>
      {/* <Section style={sectionsCount === 1 ? { paddingBottom: 0 } : null}> */}
      <Section>
        <LocationNearYou />
      </Section>
    </>
  );
};

export default LocationsPage;
