import PropTypes from 'prop-types';
import classnames from 'classnames';
import CheckmarkIcon from '@icons/checkmark.svg';
import { useContext } from 'react';
import i18nStringsContext from '@contexts/i18nStringsContext';
import Link from '@components/Link';
import Tooltip from '@components/Tooltip';
import SlantedButtonGroup from '@features/PageBuilder/components/SlantedButtonGroup';
import Price from '@components/Price';
import HTMLText from '@features/PageBuilder/components/HTMLText';
import useLocaleUrl from '@hooks/use-locale-url';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import Button from '@components/Button';
import ConditionalWrapper from '@components/ConditionalWrapper';
import Heading from '../../../../../../../../shared/components/Heading/Heading';
import {
  Root,
  Wrapper,
  WrapperInner,
  WrapperInnerBottom,
  Muted,
  Inner,
  PriceModelName,
  PriceWrapper,
  Price as PriceClass,
  PriceInterval,
  BelowPrice,
  IsCampaign,
  FeatureList,
  MissingFeature,
  WrapperSeperator,
  MutedText,
  ButtonGroup as ButtonGroupClass,
  ButtonClass,
  IsPopular,
  IsPopularWrapper,
  IsPopularLabel,
  IsPopularContainer,
  WhiteBackground,
  CampaignButtonInner,
  CampaignPriceWrapper,
  BeforeCampaignPrice,
  InfoIcon,
  InformationText,
  VatText,
  priceDecimal,
  flexContainer,
  PriceIntervalBusiness,
  ButtonClassDisabled,
} from './PriceModel.module.css';

const PriceModel = ({
  id,
  className,
  name,
  price,
  features,
  singleWashPrice,
  signupFee,
  isPopular,
  isBusiness,
  group,
  whiteBackground,
  isCampaign,
  campaignPrice,
  description,
  link,
  information: {
    information_text: informationText,
    information_link: informationLink,
  },
}) => {
  const classes = classnames(
    Root,
    {
      [WhiteBackground]: whiteBackground,
      [IsCampaign]: isCampaign,
      [IsPopular]: isPopular,
    },
    className
  );

  const actualSignupFee = isCampaign ? campaignPrice || 0 : signupFee;
  const { locale } = useRouter();

  const {
    readMore: readMoreString,
    becomeMember: becomeMemberString,
    exclusiveVats: exclusiveVatsString,
    orTryASinglewash: orTryASinglewashString,
    singleWashLink: singleWashLinkString,
    minimumPriceFirstMonth: minimumPriceFirstMonthString,
    isPopular: isPopularString,
    monthlyCurrencyShorthand,
    orTryUnlimitedWash: orTryUnlimitedWashString,
    unlimitedLinkText,
    signupForAmount: signupForAmountString,
    signupFee: signupFeeString,
  } = useContext(i18nStringsContext);

  const localeUrl = useLocaleUrl();

  const { options } = useSelector((state) => state.wordpress);

  const {
    singleWashLink,
    unlimitedWashLink,
    currencySymbol,
    priceModels,
    countryCode,
  } = options || {};

  const floorPrice = Math.floor(price);
  const formatDecimal = price.toFixed(2);
  const decimal = formatDecimal.toString().slice(-2);

  const floorPriceSingleWash = Math.floor(singleWashPrice);
  const formatDecimalSingleWash = singleWashPrice.toFixed(2);
  const decimalSingleWash = formatDecimalSingleWash.toString().slice(-2);
  const showsPriceChanges =
    priceModels?.find((priceWarning) => priceWarning.priceWarning)
      ?.priceWarning !== '' && !['DE'].includes(countryCode);
  const priceChangesText = priceModels?.find(
    (priceWarning) => priceWarning.priceWarning
  )?.priceWarning;

  return (
    <div className={classes}>
      {isPopular && (
        <div className={IsPopularContainer}>
          <div className={IsPopularWrapper}>
            <div className={IsPopularLabel}>
              {isPopularString.toUpperCase()}
            </div>
          </div>
        </div>
      )}
      <div className={Wrapper}>
        <Heading element="h5" className={PriceModelName} hasHTML={false}>
          {name}
          {description && (
            <Tooltip className={InfoIcon} header={name} content={description} />
          )}
        </Heading>
        <div className={PriceWrapper}>
          <span className={PriceClass}>
            {group === 'singlewash' ? floorPriceSingleWash : floorPrice}
          </span>
          {isBusiness || locale === 'de' ? (
            <div className={flexContainer}>
              <span className={priceDecimal}>
                {group === 'singlewash' ? decimalSingleWash : decimal}
              </span>
              <span className={PriceIntervalBusiness}>
                {group !== 'singlewash'
                  ? monthlyCurrencyShorthand
                  : currencySymbol}
              </span>
            </div>
          ) : (
            <span className={PriceInterval}>
              {group !== 'singlewash'
                ? monthlyCurrencyShorthand
                : currencySymbol}
            </span>
          )}
        </div>
        <div
          className={BelowPrice}
          // to do: use <Link> component instead of dangerouslySetInnerHTML
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html:
              group === 'singlewash'
                ? (orTryUnlimitedWashString || '')
                    .replace(
                      '{unlimited}',
                      `<a href="${
                        unlimitedWashLink?.url || '#'
                      }?plan=${id}">${unlimitedLinkText}</a>`
                    )
                    .replace(
                      '{price}',
                      `${
                        isBusiness
                          ? formatDecimal.replace('.', ',')
                          : price.toString().replace('.', ',')
                      } ${monthlyCurrencyShorthand}`
                    )
                : (orTryASinglewashString || '')
                    .replace(
                      '{singleWashLink}',
                      `<a href="${singleWashLink?.url || '#'}?plan=${id}">${
                        singleWashLinkString || ''
                      }</a>`
                    )
                    .replace(
                      '{price}',
                      isBusiness || locale === 'de'
                        ? formatDecimalSingleWash.replace('.', ',')
                        : singleWashPrice
                    ),
          }}
        />
        {/* {isPopular ? (
          <Label className={IsPopularLabel}>{isPopularString}</Label>
        ) : null} */}
        {isBusiness && <span className={VatText}>{exclusiveVatsString}</span>}
      </div>
      <div className={WrapperSeperator} />
      <div className={Wrapper}>
        <div className={WrapperInner}>
          {features?.length > 0 && (
            <div>
              <ul className={FeatureList}>
                {features.map(({ name: featureName, enabled }) => (
                  <li
                    key={featureName}
                    className={!enabled ? MissingFeature : null}
                  >
                    <CheckmarkIcon />
                    {featureName}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {group !== 'singlewash' && !isCampaign ? (
            <div className={WrapperInnerBottom}>
              <div className={Inner}>
                <div className={MutedText}>
                  <p>{minimumPriceFirstMonthString.split('{price}')[0]}</p>
                  <Price
                    amount={actualSignupFee}
                    showDecimals={locale === 'de'}
                  />
                  <p>{minimumPriceFirstMonthString.split('{price}')[1]}</p>
                </div>

                <ConditionalWrapper
                  condition={!!informationLink}
                  wrapper={(children) => (
                    <Link href={informationLink} className={InformationText}>
                      {children}
                    </Link>
                  )}
                >
                  <p className={MutedText}>{informationText}</p>
                </ConditionalWrapper>
                {showsPriceChanges && (
                  <div className={InformationText}>
                    <HTMLText>{priceChangesText}</HTMLText>
                  </div>
                )}
              </div>
              <div className={Inner}>
                <Button
                  appearance="text"
                  element={Link}
                  className={[ButtonClass, Muted].join(' ')}
                  href={link?.url}
                  id={`read-more-plan-${id}`}
                >
                  {isCampaign ? (
                    <span className={CampaignPriceWrapper}>
                      {signupFeeString}
                      <div className={BeforeCampaignPrice}>
                        <Price
                          amount={actualSignupFee}
                          showDecimals={locale === 'de'}
                        />
                      </div>
                    </span>
                  ) : (
                    readMoreString
                  )}
                </Button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <SlantedButtonGroup
        className={ButtonGroupClass}
        isSplash={isCampaign}
        buttons={[
          {
            element: 'p',
            appearance: 'white',
            href: '#',
            innerClass: isCampaign ? CampaignButtonInner : null,
            className: ButtonClassDisabled,
            text: <></>,
          },
          {
            element: Link,
            appearance: 'white',
            href:
              group !== 'singlewash'
                ? localeUrl(
                    `/checkout?plan=${id}&single=0${
                      isBusiness ? '&business=1' : ''
                    }`
                  )
                : link?.url,
            className: ButtonClass,
            id: `pricelist-read-more-${id}`,
            text: (
              <>
                {!isCampaign &&
                  (group === 'singlewash'
                    ? readMoreString
                    : becomeMemberString)}
                {isCampaign &&
                  (signupForAmountString || '').replace(
                    '{amount}',
                    campaignPrice || 0
                  )}
              </>
            ),
            // for tracking purposes
            ...(group === 'singlewash' && {
              id: `pricelist-find-washhall-${id}`,
            }),
            ...(group !== 'singlewash' && {
              id: `pricelist-become-member-${id}`,
            }),
          },
        ]}
      />
    </div>
  );
};

PriceModel.defaultProps = {
  isPopular: false,
  isBusiness: false,
  whiteBackground: false,
  campaignPrice: 0,
  group: 'recurring',
};

PriceModel.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  singleWashPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  signupFee: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      enabled: PropTypes.bool,
    })
  ).isRequired,
  group: PropTypes.oneOf(['recurring', 'singlewash', 'selfwash']),
  isPopular: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  isBusiness: PropTypes.bool,
  whiteBackground: PropTypes.bool,
  campaignPrice: PropTypes.number,
};

export default PriceModel;
