import Accordion from '@components/Accordion';
import Button from '@components/Button';
import Container from '@components/Container';
import Link from '@components/Link';
import Section from '@components/Section';
import Text from '@features/PageBuilder/components/Sections/components/Text';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import {
  Root,
  Accordion as AccordionClass,
  SecondButton,
} from './Error.module.css';

const Error = ({ errorType, errors = [] }) => {
  const { t } = useTranslation(['account', 'common']);
  const [errorMessageOpen, setErrorMessageOpen] = useState(false);
  return (
    <Container>
      <Section className={Root}>
        <Text
          subheader={t('errorSubheader', { ns: 'common' })}
          header={t('errorHeader', { ns: 'common' })}
          textPosition="center"
          features={['half_width']}
          content={t('errorContent', { ns: 'common' })}
        />
        <Button
          onClick={() => window.location.reload(true)}
          appearance="secondary"
          hasArrow={false}
        >
          {t('reRenderPage', { ns: 'common' })}
        </Button>
        <br />
        <Button
          className={SecondButton}
          element={Link}
          href="/"
          appearance="primary"
        >
          {t('goToFrontPage', { ns: 'common' })}
        </Button>
        {process.env.NEXT_PUBLIC_DEBUG && errors?.length > 0 && (
          <Accordion
            className={AccordionClass}
            header={errorType || 'Error message'}
            didClick={() => setErrorMessageOpen(!errorMessageOpen)}
            isOpen={errorMessageOpen}
            whiteBackground={false}
          >
            {errors.map((error, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <span key={index}>
                {error.message}
                <br />
              </span>
            ))}
          </Accordion>
        )}
      </Section>
    </Container>
  );
};

export default Error;
